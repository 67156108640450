<template>
  <button
      ref="tooltipButton"
      type="button"
      class="tooltip-button ml-1"
      :aria-label="buttonAriaLabel"
  >
    <slot name="button-content">
      <svg class="w-4 h-4 text-gray-400 hover:text-gray-500" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path>
      </svg>
    </slot>
  </button>
  <div
      ref="tooltipContent"
      class="tooltip-content absolute z-10 invisible inline-block text-sm font-light text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72"
  >
    <div class="p-3" :class="{ 'space-y-2': title }">
      <h3 v-if="title" class="font-semibold text-gray-900">{{ title }}</h3>
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUpdated } from 'vue';
import { Tooltip } from 'flowbite';

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: ''
  },
  description: {
    type: String,
    required: true
  },
  buttonAriaLabel: {
    type: String,
    default: 'Show information'
  },
  placement: {
    type: String,
    default: 'bottom-start'
  }
});

const tooltipButton = ref(null);
const tooltipContent = ref(null);

const initializeTooltip = () => {
  if (tooltipButton.value && tooltipContent.value) {
    new Tooltip(tooltipContent.value, tooltipButton.value, {
      placement: props.placement,
      triggerType: 'click',
      offset: 10,
    });
  }
};

onMounted(initializeTooltip);
onUpdated(initializeTooltip);
</script>