<template>
  <div class="bg-white p-6 rounded-md shadow-sm">
    <h3 class="text-lg font-semibold mb-4">{{ trans('leakblock.selfReportedLastLeaksForm.title') }}</h3>
    <form @submit.prevent="submitLeak">
      <div class="mb-4">
        <label for="link" class="block text-sm font-medium text-gray-700">{{ trans('leakblock.selfReportedLastLeaksForm.linkLabel') }}</label>
        <input type="url" id="link" v-model="form.link" required class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50">
      </div>
      <div class="mb-4">
        <label for="searchEngine" class="block text-sm font-medium text-gray-700">{{ trans('leakblock.selfReportedLastLeaksForm.searchEngineLabel') }}</label>
        <select
            id="searchEngine"
            v-model="form.searchEngineId"
            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50"
        >
          <option v-for="engine in searchEngines" :key="engine.id" :value="engine.id">{{ engine.name }}</option>
        </select>
      </div>
      <div class="mb-4">
        <label for="searchTerms" class="block text-sm font-medium text-gray-700">{{ trans('leakblock.selfReportedLastLeaksForm.searchTermsLabel') }}</label>
        <input type="text" id="searchTerms" v-model="form.searchTerms" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50">
      </div>
      <div class="mb-4">
        <label for="comment" class="block text-sm font-medium text-gray-700">{{ trans('leakblock.selfReportedLastLeaksForm.commentLabel') }}</label>
        <textarea id="comment" v-model="form.comment" rows="1" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50"></textarea>
      </div>
      <div class="flex justify-end">
        <button type="button" @click="$emit('cancel')" class="mr-2 px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
          {{ trans('leakblock.selfReportedLastLeaksForm.cancelButton') }}
        </button>
        <button type="submit" class="flex items-center text-white bg-primary-500 hover:bg-primary-400 px-4 py-2 transition rounded-md self-center font-medium">
          {{ trans('leakblock.selfReportedLastLeaksForm.submitButton') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed } from 'vue';
import axios from 'axios';
import { trans } from "../../../js/utilities/leakblock-translations";

const props = defineProps({
  accountUrl: {
    type: String,
    required: true
  }
});

const emit = defineEmits(['cancel', 'submitted']);

const form = ref({
  link: '',
  comment: '',
  searchEngineId: '',
  searchTerms: ''
});

const searchEngines = ref([]);
const previewLink = ref('');

const fetchSearchEngines = async () => {
  try {
    const response = await axios.get('/api/search-engines');
    searchEngines.value = response.data;
    // Preselect the first search engine if available
    if (searchEngines.value.length > 0) {
      form.value.searchEngineId = searchEngines.value[0].id;
    }
  } catch (error) {
    console.error('Error fetching search engines:', error);
  }
};

onMounted(fetchSearchEngines);

const generatePreviewLink = () => {
  if (form.value.searchEngineId && form.value.link) {
    const selectedEngine = searchEngines.value.find(engine => engine.id === form.value.searchEngineId);
    if (selectedEngine) {
      const siteParameter = form.value.link.replace(/^(https?:\/\/)?(www\.)?/, '').split('/')[0];
      return selectedEngine.searchQuerySyntax
          .replace('{site}', siteParameter)
          .replace('{terms}', encodeURIComponent(form.value.searchTerms));
    }
  }
  return '';
};

const computedPreviewLink = computed(() => generatePreviewLink());

watch([() => form.value.link, () => form.value.searchEngineId, () => form.value.searchTerms], () => {
  previewLink.value = computedPreviewLink.value;
});

const submitLeak = async () => {
  try {
    const response = await axios.post('/api/leaks', {
      link: form.value.link, // Original link
      search_engine_link: previewLink.value, // Search engine link
      comment: form.value.comment,
      account_url: props.accountUrl,
      is_self_reported: true,
      search_engine_id: form.value.searchEngineId,
      search_terms: form.value.searchTerms
    });

    emit('submitted', response.data);
    form.value = { link: '', comment: '', searchEngineId: '', searchTerms: '' };
  } catch (error) {
    console.error('Error submitting leak:', error);
  }
};
</script>