import axios from 'axios';

const API_URL = '/api';

export const getLeaks = (accountUrl, page = 1, perPage = 20) => {
    return axios.get(`${API_URL}/leaks`, {
        params: {
            account_url: accountUrl,
            page: page,
            per_page: perPage
        }
    });
}

export const getLeak = (id) => {
    return axios.get(`${API_URL}/leaks/p/${id}`);
}

export const createLeak = (data) => {
    return axios.post(`${API_URL}/leaks`, data);
}

export const getLatestLeaks = (accountUrl) => {
    return axios.get(`${API_URL}/latest-leaks`, {
        params: {
            account_url: accountUrl
        }
    });
}

export const getLatestSelfReportedLeaks = (accountUrl) => {
    return axios.get(`${API_URL}/latest-self-reported-leaks`, {
        params: {
            account_url: accountUrl
        }
    });
}
