// assets/app.js
import { registerVueControllerComponents } from '@symfony/ux-vue';
import { createApp } from 'vue';
import './bootstrap.js';
import './styles/app.css';
import './scripts/charts';
import 'flowbite/dist/flowbite.js';
import router from './vue/router';
import moment from "moment";
import { initializeSessionChecker } from './js/utilities/axios-setup';

const app = createApp({});

registerVueControllerComponents(require.context('./vue/controllers', true, /\.vue$/));

app.use(router);

initializeSessionChecker(router);

app.directive("click-outside", {
    beforeMount: function (el, binding) {
        // Define ourClickEventHandler
        const ourClickEventHandler = event => {
            if (!el.contains(event.target) && el !== event.target) {
                binding.value(event);
            }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;

        document.addEventListener("click", ourClickEventHandler);
    },
    unmounted: function (el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
    }
})

app.mount('#app');

export function formatDate(value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY');
    }
    return value;
}


