<!-- Layout.vue -->
<template>
  <div>
    <template v-if="user?.email">
      <UserHeader :user="user" />
      <router-view v-slot="{ Component }">
        <transition name="slide-fade" mode="out-in">
          <component :is="Component" :key="mainRouteKey" :user="user" v-bind="$route.params" />
        </transition>
      </router-view>
    </template>

    <div v-else-if="isLoadingUser" class="flex items-center justify-center min-h-screen">
      <div class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import UserHeader from "./UserHeader.vue";

const route = useRoute();
const user = ref(null);
const isLoadingUser = ref(true);

// Compute a key based on the main route path
const mainRouteKey = computed(() => {
  const pathParts = route.path.split('/');
  return pathParts.length > 2 ? `${pathParts[1]}/${pathParts[2]}` : route.path;
});

const fetchCurrentUser = async () => {
  try {
    isLoadingUser.value = true;
    const response = await axios.get('/api/current-user');
    user.value = response.data;
  } catch (error) {
    console.error('Error fetching current user:', error);
  } finally {
    isLoadingUser.value = false;
  }
};

onMounted(() => {
  fetchCurrentUser();
});
</script>