<template>
  <div class="container bg-gray-100 pt-[30px] lg:pt-[45px] pb-[30px]">
    <div class="md:max-w-auto min-w-0 max-w-full flex-1 rounded-[1.3rem] pb-10 before:block before:h-px before:w-full before:content-['']">
      <div class="intro-y mt-6 flex flex-col items-center sm:flex-row">
        <h2 class="mr-auto text-lg font-medium">{{ trans('leakblock.messenger.title') }}</h2>
        <div class="mt-4 flex w-full sm:mt-0 sm:w-auto">
          <button
              @click.prevent="openNewChatModal"
              class="w-full transition duration-200 border inline-flex items-center justify-center py-2 px-3 rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus-visible:outline-none [&:hover:not(:disabled)]:bg-opacity-90 [&:hover:not(:disabled)]:border-opacity-90 [&:not(button)]:text-center disabled:opacity-70 disabled:cursor-not-allowed bg-primary-500 border-primary-500 text-white dark:border-primary-500 shadow-md"
          >
            {{ trans('leakblock.messenger.startNewChat') }}
          </button>
        </div>
      </div>
      <div v-if="errorMessage" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
        <span class="block sm:inline">{{ errorMessage }}</span>
      </div>
      <div class="intro-y mt-2 grid grid-cols-12 gap-5">
        <!-- Mobile chat selection toggle button -->
        <div class="col-span-12 lg:hidden">
          <button @click="toggleMobileChatList" class="w-full bg-white text-primary-600 px-4 py-2 rounded-md shadow">
            {{ showMobileChatList ? trans('leakblock.messenger.hideChatList') : trans('leakblock.messenger.showChatList') }}
          </button>
        </div>

        <!-- Chat list (desktop) -->
        <div class="col-span-12 lg:col-span-4 2xl:col-span-3 hidden lg:block">
          <div id="chats" aria-labelledby="chats-tab" class="tab-pane active">
            <div v-if="chats && chats.length > 0" class="overflow-y-auto">
              <div v-for="chat in sortedChats"
                   :key="chat.identifier"
                   @click="selectChat(chat)"
                   class="cursor-pointer box relative flex items-center p-5 mb-2"
                   :class="{'!bg-primary-500 text-white': selectedChat && selectedChat.identifier === chat.identifier}">
                <div class="w-full overflow-hidden">
                  <div class="flex items-center justify-between">
                    <div class="flex-grow">
                    <span class="font-medium truncate block">
                      {{ chat.type === 'team' ? 'Leakblock Team' : `Leak ${chat.relatedLeak?.website || 'Unknown'}` }}
                    </span>
                                <span v-if="chat.type !== 'team'" class="text-xs text-slate-400 block">
                      {{ chat.relatedLeak?.leak_id }}
                    </span>
                    </div>
                    <div class="text-xs text-slate-400 ml-2 flex-shrink-0">
                      {{ formatDate(chat.lastMessage?.createdAt || chat.createdAt) }}
                    </div>
                  </div>
                  <div class="mt-0.5 w-full truncate text-slate-500">
                    {{ formatLastMessage(chat) }}
                  </div>
                </div>
                <div v-if="chat.unreadCount && chat.unreadCount > 0"
                     class="absolute right-0 top-0 -mr-1 -mt-1 flex h-5 w-5 items-center justify-center rounded-full bg-primary-500 text-xs font-medium text-white">
                  {{ chat.unreadCount }}
                </div>
                <button v-if="!chat.lastMessage"
                        @click.stop="deleteChat(chat)"
                        class="absolute bottom-2 right-2 text-gray-400 hover:text-red-500">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Chat messages -->
        <div class="intro-y col-span-12 lg:col-span-8 2xl:col-span-9">
          <div v-if="showLeakSelection" class="chat-box box h-[500px] md:h-[782px] p-5">
            <h3 class="text-lg font-medium mb-4">{{ trans('leakblock.messenger.selectALeak') }}</h3>
            <div v-if="isLoadingLeaks" class="flex items-center justify-center h-full">
              <div class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
            </div>
            <div v-else-if="userLeaks.length > 0" class="overflow-y-auto h-[90%]">
              <div v-for="leak in userLeaks" :key="leak.id" @click="selectLeak(leak.leak_id)" class="p-4 border rounded cursor-pointer hover:bg-gray-100 mb-2">
                <h4 class="font-medium">Leak #{{ leak.website }}</h4>
                <p class="text-sm text-gray-600">{{ leak.leak_id }}</p>
                <p class="text-xs text-gray-500">{{ formatDate(leak.created_at) }}</p>
              </div>
            </div>
            <div v-else class="flex items-center justify-center h-full">
              <p>{{ trans('leakblock.messenger.noAccountsYet') }}</p>
            </div>
            <button @click="cancelLeakSelection" class="mt-4 text-gray-500">{{ trans('leakblock.messenger.cancel') }}</button>
          </div>
          <div v-else class="chat-box box h-[500px] md:h-[782px]">
            <div class="chat-box box h-[500px] md:h-[782px]">
              <div v-if="selectedChat" class="chat-box box h-[500px] md:h-[782px]">
                <div class="flex flex-col h-full">
                  <div id="chat-bg" class="absolute w-full h-full"></div>
                  <div ref="messagesContainer" class="flex-grow overflow-y-auto p-4 z-1 relative">
                    <div v-if="isLoadingMessages" class="flex justify-center items-center h-full">
                      <div class="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
                    </div>
                    <template v-else>
                      <div v-for="message in messages" :key="message.id" class="mb-4" :class="{'text-right': message.sender?.customerNumber === props.user.customerNumber}">
                        <div class="inline-block rounded-lg p-2 max-w-[90%] text-left text-white"
                             :class="message.sender?.customerNumber === props.user.customerNumber ? 'bg-primary-500' : 'bg-gray-700'">
                          <div class="text-sm whitespace-pre-wrap emoji-font">{{ message.content }}</div>
                          <span class="text-xs text-gray-100">{{ formatDate(message.createdAt) }}</span>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div class="flex bg-white items-center border-t border-slate-200/60 pb-10 pt-4 sm:py-4 z-1 relative">
                    <textarea
                        v-model="newMessage"
                        @input="autoGrow"
                        @keydown.enter.exact.prevent="handleEnterKey"
                        ref="messageInput"
                        rows="1"
                        :placeholder="trans('leakblock.messenger.typeMessage')"
                        :style="{ height: 'auto', minHeight: '46px' }"
                        class="disabled:bg-slate-100 disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent [&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent transition duration-200 ease-in-out w-full text-sm rounded-md placeholder:text-slate-400/90 focus:ring-primary focus:ring-opacity-20 focus:border-opacity-40 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80 group-[.form-inline]:flex-1 group-[.input-group]:rounded-none group-[.input-group]:[&:not(:first-child)]:border-l-transparent group-[.input-group]:first:rounded-l group-[.input-group]:last:rounded-r group-[.input-group]:z-10 resize-none border-transparent px-5 py-3 shadow-none focus:border-transparent focus:ring-0 dark:bg-darkmode-600"
                    ></textarea>
                    <div class="absolute bottom-0 left-0 mb-5 ml-5 flex sm:static sm:mb-0 sm:ml-0">
                      <div class="relative">
                        <button v-if="!isTouchDevice" @click="toggleEmojiPicker" class="dropdown relative mr-3 sm:mr-5">
                          😊
                        </button>
                        <div v-if="showEmojiPicker" class="absolute bottom-full right-0 mb-2">
                          <Picker
                              :data="emojiIndex"
                              native="true"
                              @select="onEmojiSelect"
                              title="Pick your emoji…"
                          />
                        </div>
                      </div>
                    </div>
                    <button class="mr-5 flex h-8 w-8 flex-none items-center justify-center rounded-full bg-primary-500 text-white sm:h-10 sm:w-10"
                            @click="sendMessage"
                            :disabled="isSending">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="stroke-1.5 h-4 w-4"><path d="m22 2-7 20-4-9-9-4Z"></path><path d="M22 2 11 13"></path></svg>
                    </button>
                  </div>
                </div>
              </div>
              <div v-else class="flex h-full items-center">
                <div class="mx-auto text-center">
                  <div class="mt-3">
                    <div class="font-medium">{{ trans('leakblock.messenger.selectChat') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile chat list (slide-over) -->
    <div v-if="showMobileChatList" class="fixed inset-0 bg-white z-50 lg:hidden">
      <div class="flex flex-col h-full">
        <div class="px-4 py-6 border-b">
          <div class="flex justify-between items-center">
            <h2 class="text-lg font-medium text-gray-900">{{ trans('leakblock.messenger.chatList') }}</h2>
            <button @click="toggleMobileChatList" class="text-gray-500 hover:text-gray-700">
              <span class="sr-only">{{ trans('leakblock.messenger.closePanel') }}</span>
              <!-- Heroicon name: outline/x -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
        <div class="flex-1 overflow-y-auto px-4 py-6">
          <div v-if="chats && chats.length > 0" class="space-y-2">
            <div v-for="chat in sortedChats" :key="chat.id" @click="selectChatAndCloseMobileList(chat)" class="cursor-pointer relative p-3 rounded-md hover:bg-gray-100" :class="{'bg-primary-100': selectedChat && selectedChat.id === chat.id}">
              <div class="flex items-center justify-between">
                <span class="font-medium truncate">
                  {{ chat.type === 'team' ? 'Leakblock Team' : `Leak ${chat.relatedLeak?.website || 'Unknown'}` }}
                </span>
                <span class="text-xs text-gray-500">
                  {{ formatDate(chat.lastMessage?.createdAt || chat.createdAt) }}
                </span>
              </div>
              <p class="text-sm text-gray-600 truncate">{{ formatLastMessage(chat) }}</p>
              <button v-if="!chat.lastMessage"
                      @click.stop="deleteChat(chat)"
                      class="absolute bottom-2 right-2 text-gray-400 hover:text-red-500">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
          <div v-else class="text-center py-4">
            <p class="text-center text-gray-500">{{ trans('leakblock.messenger.noChats') }}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showNewChatModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]">
      <!-- Add click handler to the overlay -->
      <div class="absolute inset-0" @click="showNewChatModal = false"></div>

      <!-- Add stop propagation to prevent clicks on the modal from closing it -->
      <div class="bg-white p-6 mx-4 md:mx-auto rounded-lg relative z-10" @click.stop>
        <h3 class="text-lg font-medium mb-4">{{ trans('leakblock.messenger.startNewChatTitle') }}</h3>
        <div class="mb-4">
          <button @click="startNewChat('team')" class="w-full bg-primary-500 text-white px-4 py-2 rounded mb-2">
            {{ trans('leakblock.messenger.chatWithTeam') }}
          </button>
          <button @click="startNewChat('leak')" class="w-full bg-primary-500 text-white px-4 py-2 rounded">
            {{ trans('leakblock.messenger.chatAboutLeak') }}
          </button>
        </div>
        <button @click="showNewChatModal = false" class="text-gray-500">{{ trans('leakblock.messenger.cancel') }}</button>
      </div>
    </div>

    <!-- Snackbar component -->
    <Snackbar :message="snackbarMessage" :show="showSnackbar" @close="closeSnackbar" />
  </div>
</template>

<script setup>
import { ref, onMounted, computed, onUnmounted, watch, nextTick } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import Snackbar from "../Snackbar/Snackbar.vue";
import data from "emoji-mart-vue-fast/data/all.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";
import { trans } from "../../../js/utilities/leakblock-translations";

const props = defineProps({
  user: {
    type: Object,
    required: true
  }
});

const route = useRoute();
const router = useRouter();
const chats = ref([]);
const selectedChat = ref(null);
const messages = ref([]);
const newMessage = ref('');
const isLoadingChats = ref(true);
const isLoadingMessages = ref(false);
const isSending = ref(false);
const showNewChatModal = ref(false);
const errorMessage = ref('');
const eventSource = ref(null);
const showLeakSelection = ref(false);
const userLeaks = ref([]);
const isLoadingLeaks = ref(false);
const userAccountUrl = ref('');
const userAccountUrls = ref([]);
const snackbarMessage = ref('');
const showSnackbar = ref(false);
const showEmojiPicker = ref(false);
const emojiIndex = new EmojiIndex(data);
const messagesContainer = ref(null);
const isUserScrolling = ref(false);
const eventSources = ref({});
const showMobileChatList = ref(false);
const messageInput = ref(null);
let scrollTimeout;

const toggleMobileChatList = () => {
  showMobileChatList.value = !showMobileChatList.value;
};

const selectChatById = async (chatId) => {
  const chat = chats.value.find(c => c.identifier === chatId);
  if (chat) {
    await selectChat(chat);
  }
};

const autoGrow = () => {
  if (messageInput.value) {
    const lineHeight = parseInt(window.getComputedStyle(messageInput.value).lineHeight);
    const maxHeight = lineHeight * 5; // 5 rows maximum

    messageInput.value.style.height = 'auto';
    const scrollHeight = messageInput.value.scrollHeight;
    messageInput.value.style.height = Math.min(scrollHeight, maxHeight) + 'px';

    // Show scrollbar if content exceeds max height
    messageInput.value.style.overflowY = scrollHeight > maxHeight ? 'auto' : 'hidden';
  }
};

const handleLeakChat = async (leakId) => {
  // Check if a chat for this leak already exists
  const existingLeakChat = chats.value.find(chat => chat.type === 'leak' && chat.relatedLeak?.leak_id === leakId);

  if (existingLeakChat) {
    // If the chat exists, select it
    await selectChat(existingLeakChat);
  } else {
    // If the chat doesn't exist, create a new one
    try {
      const response = await axios.post('/api/chats', {
        type: 'leak',
        leakId: leakId.toString() // Ensure leakId is sent as a string
      });
      await fetchChats(); // Refresh the chat list
      await selectChat(response.data);
    } catch (error) {
      console.error('Error creating new leak chat:', error);
      // Handle error (e.g., show an error message to the user)
    }
  }
};

const deleteChat = async (chat) => {
  try {
    await axios.delete(`/api/chats/${chat.identifier}`);
    chats.value = chats.value.filter(c => c.identifier !== chat.identifier);
    if (selectedChat.value?.identifier === chat.identifier) {
      selectedChat.value = null;
    }
  } catch (error) {
    handleError(error, 'deleting chat');
  }
};

const isTouchDevice = computed(() => {
  return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);
});

const selectChatAndCloseMobileList = (chat) => {
  selectChat(chat);
  showMobileChatList.value = false;
};

const sortedChats = computed(() => {
  return [...chats.value].sort((a, b) => {
    const dateA = a.lastMessage ? new Date(a.lastMessage.createdAt) : new Date(a.createdAt);
    const dateB = b.lastMessage ? new Date(b.lastMessage.createdAt) : new Date(b.createdAt);
    return dateB - dateA;
  });
});

const formatLastMessage = (chat) => {
  if (chat.lastMessage) {
    const prefix = chat.lastMessage.sender.customerNumber === props.user.customerNumber ? 'Me: ' : 'Leakblock: ';
    return prefix + chat.lastMessage.content;
  }
  return trans('leakblock.messenger.noMessagesYet');
};

const scrollToBottom = () => {
  if (messagesContainer.value) {
    messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
  }
};

const subscribeToAllChats = () => {
  chats.value.forEach(chat => {
    subscribeToChat(chat.identifier);
  });
};

const handleScroll = () => {
  isUserScrolling.value = true;
  clearTimeout(scrollTimeout);
  scrollTimeout = setTimeout(() => {
    isUserScrolling.value = false;
  }, 1000);
};

const handleEnterKey = () => {
  newMessage.value += '\n';
};

const toggleEmojiPicker = () => {
  showEmojiPicker.value = !showEmojiPicker.value;
};

const onEmojiSelect = (emoji) => {
  newMessage.value += emoji.native;
  showEmojiPicker.value = false;
};

const fetchUserAccountUrls = async () => {
  try {
    const response = await axios.get('/api/user-account-urls');
    userAccountUrls.value = response.data.accountUrls;
    // No error handling needed here - empty array is a valid response
  } catch (error) {
    // Only handle actual errors (network issues, 500, etc.)
    handleError(error, 'fetching user account URLs');
  }
};

const fetchUserLeaks = async () => {
  isLoadingLeaks.value = true;
  userLeaks.value = [];
  try {
    if (userAccountUrls.value.length === 0) {
      await fetchUserAccountUrls();
    }

    // Remove the error throw here - empty accountUrls is valid
    if (userAccountUrls.value.length === 0) {
      isLoadingLeaks.value = false;
      return; // Just return, the template will handle showing "no leaks" message
    }

    for (const accountUrl of userAccountUrls.value) {
      const response = await axios.get('/api/all-leaks', {
        params: {
          account_url: accountUrl,
          page: 1,
          per_page: 20
        }
      });
      userLeaks.value.push(...response.data.leaks);
    }

    // Sort leaks by creation date, newest first
    userLeaks.value.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  } catch (error) {
    handleError(error, 'fetching user leaks');
  } finally {
    isLoadingLeaks.value = false;
  }
};

const fetchChats = async () => {
  isLoadingChats.value = true;
  errorMessage.value = '';
  try {
    const response = await axios.get('/api/chats');

    if (Array.isArray(response.data)) {
      chats.value = response.data.map(chat => ({
        ...chat,
        messages: chat.lastMessage ? [chat.lastMessage] : []
      }));
    } else {
      console.error('Unexpected response format from /api/chats:', response.data);
      chats.value = [];
    }

    if (chats.value.length > 0) {
      await selectChat(chats.value[0]);
    } else {
      console.log('No chats available');
    }
  } catch (error) {
    handleError(error, 'fetching chats');
  } finally {
    isLoadingChats.value = false;
  }
};


const subscribeToChat = (identifier) => {
  if (eventSources.value[identifier]) {
    eventSources.value[identifier].close();
  }

  const mercureUrl = new URL('/.well-known/mercure', window.location.origin);
  mercureUrl.searchParams.append('topic', `chat-${identifier}`);

  const newEventSource = new EventSource(mercureUrl);

  newEventSource.onmessage = (event) => {
    try {
      const message = JSON.parse(event.data);
      if (!message.id) {
        message.id = Date.now().toString();
      }
      if (!message.createdAt) {
        message.createdAt = new Date().toISOString();
      }
      addMessageToChat(message, identifier);
    } catch (error) {
      console.error('Error parsing message:', error);
    }
  };

  newEventSource.onerror = (error) => {
    console.error(`EventSource error for chat ${identifier}:`, error);
    setTimeout(() => {
      subscribeToChat(identifier);
    }, 5000);
  };

  eventSources.value[identifier] = newEventSource;
};


const selectChat = async (chat) => {
  showLeakSelection.value = false;

  if (!chat || !chat.identifier) { // Change from id to identifier
    console.error('Invalid chat object:', chat);
    errorMessage.value = 'Invalid chat selected. Please try again.';
    return;
  }

  selectedChat.value = chat;
  errorMessage.value = '';
  isLoadingMessages.value = true;
  try {
    const response = await axios.get(`/api/chats/${chat.identifier}/messages`);
    messages.value = response.data;
    chat.unreadCount = 0;
  } catch (error) {
    handleError(error, 'fetching messages');
  } finally {
    isLoadingMessages.value = false;
    await nextTick(() => {
      scrollToBottom();
    });
    showMobileChatList.value = false;
  }
};

const sendMessage = async () => {
  if (!newMessage.value.trim() || isSending.value || !selectedChat.value || !selectedChat.value.identifier) return;

  isSending.value = true;
  try {
    const response = await axios.post(`/api/chats/${selectedChat.value.identifier}/messages`, {
      content: newMessage.value.trim()
    });

    newMessage.value = '';
    showEmojiPicker.value = false;

    // Ensure the chat is subscribed after sending the first message
    if (!eventSources.value[selectedChat.value.identifier]) {
      subscribeToChat(selectedChat.value.identifier);
    }
  } catch (error) {
    handleError(error, 'sending message');
  } finally {
    isSending.value = false;
  }
};

const openNewChatModal = () => {
  showNewChatModal.value = true;
};

const startNewChat = async (type) => {
  showNewChatModal.value = false;

  if (type === 'team') {
    const existingTeamChat = chats.value.find(chat => chat.type === 'team');
    if (existingTeamChat) {
      selectChat(existingTeamChat);
      triggerSnackbar(trans('leakblock.messenger.errors.teamChatExists'));
      return;
    }
  }

  if (type === 'leak') {
    showLeakSelection.value = true;
    selectedChat.value = null;
    await fetchUserLeaks();
    return;
  }

  try {
    const response = await axios.post('/api/chats', { type });
    await fetchChats();
    await selectChat(response.data);
  } catch (error) {
    handleError(error, 'starting new chat');
  }
};

const cancelLeakSelection = () => {
  showLeakSelection.value = false;
  if (selectedChat.value) {
    selectChat(selectedChat.value);
  }
};

const selectNewestChat = () => {
  if (sortedChats.value.length > 0) {
    selectChat(sortedChats.value[0]);
  }
};

const triggerSnackbar = (message) => {
  snackbarMessage.value = message;
  showSnackbar.value = true;
};

const closeSnackbar = () => {
  showSnackbar.value = false;
};

const selectLeak = async (leakId) => {
  const existingLeakChat = chats.value.find(chat => chat.type === 'leak' && chat.relatedLeak?.id === leakId);
  if (existingLeakChat) {
    showLeakSelection.value = false;
    selectChat(existingLeakChat);
    triggerSnackbar(trans('leakblock.messenger.errors.leakChatExists'));
    return;
  }

  showLeakSelection.value = false;
  try {
    const response = await axios.post('/api/chats', { type: 'leak', leakId });
    await fetchChats();
    await selectChat(response.data);
  } catch (error) {
    handleError(error, 'starting new chat about leak');
  }
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';

  let date;
  if (typeof dateString === 'number') {
    // If it's a timestamp
    date = new Date(dateString * 1000);
  } else {
    date = new Date(dateString);
  }

  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }

  return date.toLocaleString();
};

const handleError = (error, context) => {
  console.error(`Error in ${context}:`, error);
  if (error.response) {
    console.error('Response data:', error.response.data);
    console.error('Response status:', error.response.status);
    console.error('Response headers:', error.response.headers);
  } else if (error.request) {
    console.error('No response received:', error.request);
  } else {
    console.error('Error message:', error.message);
  }
  errorMessage.value = `An error occurred while ${context}. Please try again.`;
};


const addMessageToChat = (message, chatIdentifier) => {
  const chat = chats.value.find(c => c.identifier === chatIdentifier);
  if (chat) {
    if (!chat.messages) {
      chat.messages = [];
    }
    const messageExists = chat.messages.some(m => m.id === message.id);
    if (!messageExists) {
      chat.messages.push(message);
      chat.lastMessage = message;

      if (selectedChat.value && selectedChat.value.identifier === chatIdentifier) {
        if (!messages.value.some(m => m.id === message.id)) {
          messages.value.push(message);
          nextTick(scrollToBottom);
        }
        // Reset unread count for the active chat
        chat.unreadCount = 0;
      } else if (message.sender?.customerNumber !== props.user.customerNumber) {
        // Only increment unread count for messages from others
        chat.unreadCount = (chat.unreadCount || 0) + 1;
      }
    }
  }
  // Re-sort chats after updating
  chats.value.sort((a, b) => {
    const dateA = a.lastMessage ? new Date(a.lastMessage.createdAt) : new Date(a.createdAt);
    const dateB = b.lastMessage ? new Date(b.lastMessage.createdAt) : new Date(b.createdAt);
    return dateB - dateA;
  });
};

const handleResize = () => {
  if (window.innerWidth >= 1024) {
    showMobileChatList.value = false;
  }
};

onMounted(async () => {
  try {
    await fetchUserAccountUrls();
    await fetchChats();

    // Check for leakId or team chat in query parameters
    const leakId = route.query.leakId;
    const chatType = route.query.type;

    if (leakId) {
      await handleLeakChat(leakId);
    } else if (chatType === 'team') {
      await startNewChat('team'); // Reuse existing function
    } else {
      // Existing logic for chatId or selecting newest chat
      const chatId = route.query.chatId;
      if (chatId) {
        await selectChatById(chatId);
      } else {
        selectNewestChat();
      }
    }

    subscribeToAllChats();
    if (messagesContainer.value) {
      messagesContainer.value.addEventListener('scroll', handleScroll);
    }
  } catch (error) {
    handleError(error, 'initializing the chat');
  }

  window.addEventListener('resize', handleResize);
  handleResize();
});

onUnmounted(() => {
  // Close all EventSource connections
  Object.values(eventSources.value).forEach(source => source.close());
  if (messagesContainer.value) {
    messagesContainer.value.removeEventListener('scroll', handleScroll);
  }
  window.removeEventListener('resize', handleResize);
});

watch(messages, () => {
  nextTick(scrollToBottom);
}, { deep: true });
</script>

<style scoped>
/* Add this style to enable emoji rendering */
.whitespace-pre-wrap {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* Customize emoji picker size if needed */
:deep(.emoji-picker) {
  --emoji-size: 1.5rem;
  --emoji-padding: 0.25rem;
}
</style>