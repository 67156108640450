<template>
  <div style="margin-top: 60px" class="container bg-gray-100 rounded-2xl">
    <div class="md:max-h-[calc(100vh-10rem)] pt-10 pb-10 flex justify-center">
      <div class="flex flex-col md:flex-row lb-shadow max-w-full 2xl:max-w-[60vw] rounded-2xl">
        <div class="w-full flex flex-col bg-white p-10 md:p-10 justify-between items-center overflow-hidden rounded-2xl relative lb-shadow md:shadow-none">
          <div class="flex-none w-full h-full text-center">
            <div class="mb-8">
              <svg class="w-16 h-16 text-green-500 mx-auto animate-bounce" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
              </svg>
            </div>
            <h2 class="text-2xl font-bold mb-4">{{ trans('leakblock.addSocialMediaAccount.success.title') }}</h2>
            <p class="text-gray-600 mb-8">
              {{ trans('leakblock.addSocialMediaAccount.success.description') }}
            </p>
            <button @click="goToDashboard" class="mx-auto flex items-center text-white bg-primary-500 hover:bg-primary-400 px-4 py-2 transition rounded-md font-medium">
              {{ trans('leakblock.addSocialMediaAccount.success.button') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { trans, transLocale } from "../../../js/utilities/leakblock-translations";

const router = useRouter();
const goToDashboard = () => {
  router.push(transLocale() === 'de' ? '/de/dashboard' : '/en/dashboard');
};
</script>
