import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '../controllers/Dashboard.vue';
import EmptyDashboard from "../pages/Dashboard/EmptyDashboard.vue";
import AccountDetail from "../pages/Dashboard/AccountDetail.vue";
import ResetPassword from "../controllers/ResetPassword.vue";
import Login from "../controllers/Login.vue";
import LeakOverview from "../pages/Leaks/LeakOverview.vue";
import Messenger from "../pages/Chat/Messenger.vue";
import LeakDetail from "../pages/Leaks/LeakDetail.vue";
import AddSocialMediaAccount from "../pages/Account/AddSocialMediaAccount.vue";
import AddSocialMediaAccountSuccess from "../pages/Account/AddSocialMediaAccountSuccess.vue";
import EditSocialMediaAccount from "../pages/Account/EditSocialMediaAccount.vue";
import UserSettings from "../pages/User/UserSettings.vue";
import ConfirmEmail from "../pages/User/ConfirmEmail.vue";
import Layout from "../controllers/Layout.vue";

const getPreferredLanguage = () => {
    const storedLang = localStorage.getItem('preferredLanguage');
    if (storedLang) return storedLang;

    const userLang = transLocale();
    return userLang.includes('de') ? 'de' : 'en';
};

const routes = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: ':lang(de|en)/dashboard',
                component: Dashboard,
                name: 'dashboard',
                children: [
                    {
                        path: '',
                        component: EmptyDashboard,
                        name: 'dashboardRoot',
                    },
                    {
                        path: ':accountUrl',
                        component: AccountDetail,
                        name: 'accountDetail',
                    },
                    {
                        path: ':accountUrl/leaks/p/:page?',
                        component: LeakOverview,
                        name: 'leakOverview',
                        props: route => ({ page: parseInt(route.params.page) || 1 })
                    },
                    {
                        path: ':accountUrl/leaks/:leakId',
                        component: LeakDetail,
                        name: 'leakDetail',
                        props: true
                    },
                ]
            },
            {
                path: '/:lang(de|en)/messenger',
                component: Messenger,
                name: 'messenger',
            },
            {
                path: '/:lang(de|en)/settings',
                component: UserSettings,
                name: 'user-settings',
            },
            {
                path: '/:lang(de|en)/social-media-account-hinzufuegen',
                component: AddSocialMediaAccount,
                meta: { lang: 'de' }
            },
            {
                path: '/:lang(de|en)/add-social-account',
                component: AddSocialMediaAccount,
                meta: { lang: 'en' },
            },
            {
                path: '/:lang(de|en)/social-media-account-hinzufuegen/erfolg',
                component: AddSocialMediaAccountSuccess,
                meta: { lang: 'de' },
            },
            {
                path: '/:lang(de|en)/add-social-account/success',
                component: AddSocialMediaAccountSuccess,
                meta: { lang: 'en' },
            },
            {
                path: '/:lang(de|en)/edit-social-account/:accountUrl',
                name: 'editSocialAccount',
                component: EditSocialMediaAccount,
            },
            {
                path: '/:lang(de|en)/reset-password/:token',
                component: ResetPassword,
                name: 'resetPassword'
            },
            {
                path: '/:lang(de|en)/login',
                component: Login,
                name: 'login'
            },
            {
                path: '/:lang(de|en)/confirm-email/:token',
                component: ConfirmEmail,
                name: 'confirmEmail',
                props: true
            },
            {
                path: '/:catchAll(.*)',
                redirect: () => {
                    const preferredLanguage = getPreferredLanguage();
                    return `/${preferredLanguage}/dashboard`;
                }
            }
        ]
    },
    {
        path: '/:catchAll(.*)',
        redirect: () => {
            const preferredLanguage = getPreferredLanguage();
            return `/${preferredLanguage}/dashboard`;
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { top: 0 };
    }
});

// Global after-navigation hook
router.afterEach((to, from) => {
    // Check if the route has changed
    if (to.path !== from.path) {
        window.scrollTo(0, 0);
    }
});

router.beforeEach((to, from, next) => {
    const lang = to.params.lang || to.meta.lang;

    if (!['en', 'de'].includes(lang)) {
        const preferredLanguage = getPreferredLanguage();
        return next(`/${preferredLanguage}/dashboard`);
    }

    localStorage.setItem('preferredLanguage', lang);
    return next();
});

export default router;
