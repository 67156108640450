<template>
  <div style="margin-top: 60px" class="container bg-gray-100 rounded-md">
    <div class="step-container pt-10 pb-10 flex justify-center">
      <div class="step-content flex flex-col md:flex-row lb-shadow max-w-full 2xl:max-w-[60vw] rounded-md">
        <div class="w-full md:w-1/3 flex-col flex bg-gray-50 py-4 px-10 md:p-10 rounded-tl-md rounded-tr-md md:rounded-bl-md md:rounded-tl-md md:rounded-br-none md:rounded-tr-none relative">
          <h1 class="text-2xl font-bold mb-auto">{{ stepTexts.title }}</h1>
          <div>
            <h2 class="text-lg font-semibold">{{ stepTexts.step }}</h2>
            <h3 class="text-2xl font-bold mb-4 hidden md:block">{{ stepTexts.subtitle }}</h3>
            <p class="text-gray-600 mb-6 hidden md:block">{{ stepTexts.description }}</p>
          </div>
          <div class="flex justify-between items-center space-x-4 md:static md:top-auto md:w-auto absolute top-[-23px] w-[calc(100%-5rem)]">
            <div
                v-for="step in 3"
                :key="step"
                :class="{
                'w-full h-2 rounded-full': true,
                'bg-primary-500': currentStep >= step,
                'bg-gray-300': currentStep < step
              }"
            ></div>
          </div>
        </div>
        <transition name="slide-fade" mode="out-in">
          <component v-if="!loading"
                     :is="currentStepComponent"
                     :formData="formData"
                     :platforms="platforms"
                     :currentStep="currentStep"
                     :isEditing="false"
                     @next="nextStep"
                     @prev="prevStep" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import axios from 'axios'
import StepOne from "./Steps/StepOne.vue";
import StepTwo from "./Steps/StepTwo.vue";
import StepThree from "./Steps/StepThree.vue";
import { trans } from "../../../js/utilities/leakblock-translations";

const currentStep = ref(1);
const platforms = ref([]);
const loading = ref(true);

const nextStep = () => {
  currentStep.value++;
};

const prevStep = () => {
  currentStep.value--;
};

const formData = ref({
  mainPlatformId: '',
  mainPlattformProfileLink: '',
  username: '',
  linkedAccounts: [],
  customPlatformName: '',
});

const currentStepComponent = computed(() => {
  if (currentStep.value === 1) return StepOne
  if (currentStep.value === 2) return StepTwo;
  if (currentStep.value === 3) return StepThree;
});

const stepTexts = computed(() => {
  switch (currentStep.value) {
    case 1:
      return {
        title: trans('leakblock.addSocialMediaAccount.stepOneTitle'),
        step: trans('leakblock.addSocialMediaAccount.stepOneStep'),
        subtitle: trans('leakblock.addSocialMediaAccount.stepOneSubtitle'),
        description: trans('leakblock.addSocialMediaAccount.stepOneDescription')
      };
    case 2:
      return {
        title: trans('leakblock.addSocialMediaAccount.stepTwoTitle'),
        step: trans('leakblock.addSocialMediaAccount.stepTwoStep'),
        subtitle: trans('leakblock.addSocialMediaAccount.stepTwoSubtitle'),
        description: trans('leakblock.addSocialMediaAccount.stepTwoDescription')
      };
    case 3:
      return {
        title: trans('leakblock.addSocialMediaAccount.stepThreeTitle'),
        step: trans('leakblock.addSocialMediaAccount.stepThreeStep'),
        subtitle: trans('leakblock.addSocialMediaAccount.stepThreeSubtitle'),
        description: trans('leakblock.addSocialMediaAccount.stepThreeDescription')
      };
    default:
      return {
        title: "",
        step: "",
        subtitle: "",
        description: ""
      };
  }
});

onMounted(async () => {
  try {
    const response = await axios.get('/api/social-media-platforms');
    platforms.value = response.data.map(platform => ({
      id: platform.id,
      name: platform.name,
      icon: `${platform.svg_name}`,
      urlTemplate: platform.urlTemplate,
    }));
    loading.value = false;
  } catch (error) {
    console.error('Failed to fetch platforms:', error);
    loading.value = false;
  }
});
</script>

<style scoped>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.step-container {
  min-height: calc(100vh - 60px);
  max-height: calc(100vh - 10rem);
}

.step-content {
  min-height: calc(100vh - 10rem);
}

@media (max-width: 768px) {
  .step-container {
    min-height: unset;
    max-height: unset;
  }

  .step-content {
    min-height: auto;
  }
}
</style>
