<template>
  <div class="relative h-screen lg:overflow-hidden bg-primary-500 xl:bg-white
  before:hidden before:xl:block before:content-[''] before:w-[57%] before:-mt-[28%] before:-mb-[16%] before:-ml-[13%]
  before:absolute before:inset-y-0 before:left-0 before:transform before:rotate-[-4.5deg]
  before:bg-primary-100 before:rounded-[100%] after:hidden after:xl:block after:content-[''] after:w-[57%]
  after:-mt-[20%] after:-mb-[13%] after:-ml-[13%] after:absolute after:inset-y-0 after:left-0 after:transform
  after:rotate-[-4.5deg] after:bg-primary-500 after:rounded-[100%]">
    <div class="!max-w-[1800px] container relative z-10 sm:px-10">
      <div class="block grid-cols-2 gap-4 xl:grid">
        <div class="hidden min-h-screen flex-col xl:flex">
          <div class="my-auto">
            <img class="-intro-x -mt-16 w-1/2" src="/build/images/illustrations/login.svg" alt="Leakblock Login">
            <div class="-intro-x mt-10 text-4xl font-medium leading-tight text-white" v-html="trans('leakblock.forgot.headlineTextHtml')">
            </div>
            <div class="-intro-x mt-5 text-lg text-white text-opacity-70">
              {{ trans('leakblock.forgot.protectData') }}
            </div>
          </div>
        </div>
        <div class="my-10 flex h-screen py-5 xl:my-0 xl:h-auto xl:py-0">
          <div class="mx-auto my-auto w-full rounded-md bg-white px-5 py-8 shadow-md sm:w-3/4 sm:px-8 lg:w-2/4 xl:ml-20 xl:w-auto xl:bg-transparent xl:p-0 xl:shadow-none">
            <h2 class="intro-x text-center text-2xl font-bold xl:text-left xl:text-3xl">
              {{ trans('leakblock.forgot.headline') }}
            </h2>
            <div class="intro-x mt-2 text-center text-slate-400 xl:hidden">
              {{ trans('leakblock.forgot.subtitleText') }}
            </div>
            <div v-if="successMessage" class="intro-x mt-4 text-green-600">{{ successMessage }}</div>
            <form v-if="!successMessage" @submit.prevent="submitForm">
              <div class="intro-x mt-8">
                <input type="password" v-model="password" name="password" id="password" :placeholder="trans('leakblock.forgot.newPasswordPlaceholder')" required class="intro-x block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40">
                <input type="password" v-model="confirmPassword" name="confirm-password" id="confirm-password" :placeholder="trans('leakblock.forgot.confirmNewPasswordPlaceholder')" required class="intro-x mt-4 block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40">
              </div>
              <div v-if="errorMessage" class="intro-x mt-4 text-red-600">{{ errorMessage }}</div>
              <div class="intro-x mt-5 text-center xl:mt-8 xl:text-left">
                <SubmitButton :is-submitting="isSubmitting">
                  {{ trans('leakblock.forgot.resetButton') }}
                </SubmitButton>
              </div>
            </form>
            <div v-if="successMessage" class="intro-x mt-5 text-center xl:mt-8 xl:text-left">
              <a :href="loginUrl + '?reset=success'" class="w-full xl:w-60 text-white bg-primary-500 border-primary px-4 py-3 align-top rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus-visible:outline-none transition duration-200 hover:bg-opacity-90 hover:border-opacity-90 inline-block text-center">
                {{ trans('leakblock.forgot.goToLogin') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';
import SubmitButton from "../components/Buttons/SubmitButton.vue";
import {trans} from "../../js/utilities/leakblock-translations";

const props = defineProps({
  csrf_token_reset: String,
  resetPasswordUrl: String,
  loginUrl: String
});

const password = ref('');
const confirmPassword = ref('');
const errorMessage = ref('');
const successMessage = ref('');
const isSubmitting = ref(false);

const validateForm = () => {
  if (password.value !== confirmPassword.value) {
    errorMessage.value = trans('leakblock.forgot.passwordsDoNotMatch');
    return false;
  }
  if (password.value.length < 8 || !/[!@#$%^&*(),.?":{}|<>]/.test(password.value)) {
    errorMessage.value = trans('leakblock.forgot.passwordRequirements');
    return false;
  }
  return true;
};

const submitForm = async () => {
  if (!validateForm()) return;

  isSubmitting.value = true;
  errorMessage.value = '';

  try {
    const startTime = Date.now();

    const response = await axios.post(props.resetPasswordUrl, {
      password: password.value,
      confirm_password: confirmPassword.value,
      token: props.csrf_token_reset
    });

    const elapsedTime = Date.now() - startTime;
    const remainingTime = Math.max(0, 2000 - elapsedTime);

    // Ensure the spinner shows for at least 2 seconds
    await new Promise(resolve => setTimeout(resolve, remainingTime));

    if (response.data.success) {
      successMessage.value = trans('leakblock.forgot.successMessage');
    } else if (response.data.error) {
      errorMessage.value = response.data.error;
    } else {
      errorMessage.value = trans('leakblock.error.occurredDuring', {action: trans('leakblock.forgot.resetButton').toLowerCase()});
    }
  } catch (error) {
    errorMessage.value = error.response?.data?.error || trans('leakblock.error.occurredDuring', {action: trans('leakblock.forgot.resetButton').toLowerCase()});
  } finally {
    isSubmitting.value = false;
  }
};
</script>