<template>
  <transition name="fade">
    <div v-if="isVisible"
         class="fixed top-0 left-0 right-0 text-white text-center py-2 z-50"
         :style="{ backgroundColor: bgColor }">
      {{ message }}
    </div>
  </transition>
</template>

<script setup>
import { ref, watch, onMounted, computed } from 'vue';

const props = defineProps({
  message: String,
  duration: {
    type: Number,
    default: 3000
  },
  show: Boolean,
  color: {
    type: String,
    default: 'red'
  }
});

const emit = defineEmits(['close']);

const isVisible = ref(false);

const bgColor = computed(() => {
  const colors = {
    red: '#EF4444',    // Tailwind's red-500
    green: '#10B981',  // Tailwind's green-500
    yellow: '#F59E0B', // Tailwind's yellow-500
    blue: '#3B82F6',   // Tailwind's blue-500
  };
  return colors[props.color] || props.color;
});

watch(() => props.show, (newVal) => {
  if (newVal) {
    isVisible.value = true;
    setTimeout(() => {
      isVisible.value = false;
      emit('close');
    }, props.duration);
  }
});

onMounted(() => {
  if (props.show) {
    isVisible.value = true;
    setTimeout(() => {
      isVisible.value = false;
      emit('close');
    }, props.duration);
  }
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>