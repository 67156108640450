<template>
  <div style="margin-top: 60px" class="container bg-gray-100 rounded-md">
    <div class="md:min-h-[calc(100vh-60px)] md:max-h-[calc(100vh-10rem)] pt-10 pb-10 flex justify-center">
      <div class="md:min-h-[calc(100vh-10rem)] flex flex-col md:flex-row lb-shadow max-w-full 2xl:max-w-[60vw] rounded-md">
        <div class="w-full md:w-1/3 flex-col flex bg-gray-50 py-4 px-10 md:p-10 rounded-tl-md rounded-tr-md md:rounded-bl-md md:rounded-tl-md md:rounded-br-none md:rounded-tr-none relative">
          <h1 class="text-2xl font-bold mb-auto">{{ trans('leakblock.editSocialMediaAccount.title') }}</h1>
          <div>
            <h2 class="text-lg font-semibold">{{ trans('leakblock.editSocialMediaAccount.step', { step: currentStep }) }}</h2>
            <h3 class="text-2xl font-bold mb-4 hidden md:block">{{ stepTexts.subtitle }}</h3>
            <p class="text-gray-600 mb-6 hidden md:block">{{ stepTexts.description }}</p>
          </div>
          <div class="flex justify-between items-center space-x-4 md:static md:top-auto md:w-auto absolute top-[-23px] w-[calc(100%-5rem)]">
            <div
                v-for="step in 3"
                :key="step"
                :class="{
                'w-full h-2 rounded-full': true,
                'bg-primary-500': currentStep >= step,
                'bg-gray-300': currentStep < step
              }"
            ></div>
          </div>
        </div>
        <transition name="slide-fade" mode="out-in">
          <component
              v-if="!loading"
              :is="currentStepComponent"
              :formData="formData"
              :platforms="platforms"
              :currentStep="currentStep"
              :mainAccount="mainAccount"
              :isEditing="true"
              :accountUrl="accountUrl"
              @next="nextStep"
              @prev="prevStep"
              @confirm="confirmChanges"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router';
import StepOne from "./Steps/EditStepOne.vue";
import StepTwo from "./Steps/StepTwo.vue";
import StepThree from "./Steps/StepThree.vue";
import { trans } from "../../../js/utilities/leakblock-translations";

const route = useRoute();
const router = useRouter();
const accountUrl = route.params.accountUrl;

const currentStep = ref(1);
const platforms = ref([]);
const loading = ref(true);
const mainAccount = ref(null);

const nextStep = () => {
  currentStep.value++;
};

const prevStep = () => {
  currentStep.value--;
};

const formData = ref({
  mainPlatformId: '',
  mainPlattformProfileLink: '',
  linkedAccounts: [],
  customPlatformName: '',
});

const currentStepComponent = computed(() => {
  if (currentStep.value === 1) return StepOne;
  if (currentStep.value === 2) return StepTwo;
  if (currentStep.value === 3) return StepThree;
});

const stepTexts = computed(() => {
  switch (currentStep.value) {
    case 1:
      return {
        subtitle: trans('leakblock.editSocialMediaAccount.subtitle1'),
        description: trans('leakblock.editSocialMediaAccount.description1')
      };
    case 2:
      return {
        subtitle: trans('leakblock.editSocialMediaAccount.subtitle2'),
        description: trans('leakblock.editSocialMediaAccount.description2')
      };
    case 3:
      return {
        subtitle: trans('leakblock.editSocialMediaAccount.subtitle3'),
        description: trans('leakblock.editSocialMediaAccount.description3')
      };
    default:
      return {
        subtitle: "",
        description: ""
      };
  }
});

const confirmChanges = async (updatedData) => {
  try {
    const response = await axios.put(`/api/social-media-accounts/${accountUrl}`, updatedData);
    await router.push({name: 'dashboard'});
  } catch (error) {
    console.error('Error updating account:', error);
    if (error.response) {
      console.error("Error response data:", error.response.data);
      console.error("Error response status:", error.response.status);
    } else if (error.request) {
      console.error("Error request:", error.request);
    } else {
      console.error('Error message:', error.message);
    }
  }
};

onMounted(async () => {
  try {
    const [accountResponse, platformsResponse] = await Promise.all([
      axios.get(`/api/social-media-accounts/${accountUrl}`),
      axios.get('/api/social-media-platforms')
    ]);

    mainAccount.value = accountResponse.data;
    formData.value.mainPlatformId = mainAccount.value.platform.id;
    formData.value.mainPlattformProfileLink = mainAccount.value.link;
    formData.value.linkedAccounts = accountResponse.data.relatedAccounts.map(account => ({
      id: account.id,
      platformId: account.platform.id,
      profileLink: account.link,
      username: account.username,
      platformName: account.platform.name
    }));
    platforms.value = platformsResponse.data.map(platform => ({
      id: platform.id,
      name: platform.name,
      icon: `${platform.svg_name}`,
    }));
    loading.value = false;
  } catch (error) {
    console.error('Error fetching data:', error);
    loading.value = false;
  }
});
</script>

<style scoped>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
