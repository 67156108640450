<template>
  <div>
    <div class="container bg-gray-100 pt-[30px] lg:pt-[45px] pb-[30px]">
      <!-- Settings Navigation -->
      <div class="settings-tabs pt-6 mb-2 relative overflow-hidden flex items-start">
        <div class="swiper-container flex-grow overflow-hidden">
          <Swiper
              :modules="swiperModules"
              :slides-per-view="'auto'"
              :space-between="8"
              :free-mode="true"
              :mousewheel="true"
              class="settings-swiper m-0"
              @swiper="setSwiper"
          >
            <SwiperSlide
                v-for="(tab, index) in tabs"
                :key="tab.id"
                class="settings-slide"
                :class="{
                    'pre-selected': currentTab === tab.id
                  }"
                :data-swiper-slide-index="index"
            >
              <div
                  @click="currentTab = tab.id"
                  :class="{
                  'bg-white border-b-4 border-primary-500': currentTab === tab.id,
                  'bg-white text-primary-500 hover:border-b-4 hover:border-primary-500': currentTab !== tab.id
                }"
                  class="py-2 px-10 rounded-md text-center flex items-center lb-shadow cursor-pointer h-full"
              >
                {{ tab.name }}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div class="flex items-center ml-2">
          <button v-if="tabs.length > 1" @click="handlePrevClick" class="navigation-button mr-1">
            <span class="sr-only">Previous</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button v-if="tabs.length > 1" @click="handleNextClick" class="navigation-button mr-1">
            <span class="sr-only">Next</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>

      <!-- Settings Content -->
      <div class="grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
        <div class="col-span-auto">
          <!-- Account Settings -->
          <div v-show="currentTab === 'account'" class="intro-y space-y-6">
            <div class="bg-white rounded-lg shadow-sm p-6">
              <h2 class="text-2xl font-semibold text-gray-700 mb-2">{{ trans('leakblock.settings.accountSettings') }}</h2>
              <p class="text-sm font-medium text-gray-700 mb-6">{{ trans('leakblock.settings.customerNumber') }} {{user.customerNumber}}</p>
              <!-- Email Change Form -->
              <div class="max-w-md">
                <h3 class="text-lg font-medium text-gray-700 mb-4">{{ trans('leakblock.settings.changeEmailTitle') }}</h3>
                <form @submit.prevent="changeEmail" class="space-y-4">
                  <div>
                    <label for="currentEmail" class="block text-sm font-medium text-gray-700 mb-2">
                      {{ trans('leakblock.settings.currentEmail') }}
                    </label>
                    <input type="email" id="currentEmail" v-model="currentEmail" disabled
                           class="input-field">
                  </div>
                  <div>
                    <label for="newEmail" class="block text-sm font-medium text-gray-700 mb-2">
                      {{ trans('leakblock.settings.newEmail') }}
                    </label>
                    <input type="email" id="newEmail" v-model="newEmail" required
                           class="input-field">
                  </div>
                  <button type="submit" class="primary-button">
                    {{ trans('leakblock.settings.changeEmailButton') }}
                  </button>
                </form>
              </div>

              <!-- Communication Language -->
              <div class="max-w-md pt-6 mt-6 border-t">
                <h3 class="text-lg font-medium text-gray-700 mb-4">
                  {{ trans('leakblock.settings.communicationLanguage') }}
                </h3>
                <div class="space-y-4">
                  <select
                      v-model="communicationLanguage"
                      @change="updateCommunicationLanguage"
                      class="input-field"
                  >
                    <option value="en">English</option>
                    <option value="de">Deutsch</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <!-- Security Settings -->
          <div v-show="currentTab === 'security'" class="space-y-6">
            <div class="bg-white rounded-lg shadow-sm p-6 intro-y">
              <h2 class="text-2xl font-semibold text-gray-700 mb-6">{{ trans('leakblock.settings.securitySettings') }}</h2>

              <!-- Password Change Form -->
              <div class="max-w-md">
                <form @submit.prevent="changePassword" class="space-y-4">
                  <div>
                    <label for="currentPassword" class="block text-sm font-medium text-gray-700 mb-2">
                      {{ trans('leakblock.settings.currentPassword') }}
                    </label>
                    <input type="password" id="currentPassword" v-model="currentPassword" required
                           class="input-field">
                  </div>
                  <div>
                    <label for="newPassword" class="block text-sm font-medium text-gray-700 mb-2">
                      {{ trans('leakblock.settings.newPassword') }}
                    </label>
                    <input type="password" id="newPassword" v-model="newPassword" required
                           class="input-field">
                  </div>
                  <div>
                    <label for="confirmPassword" class="block text-sm font-medium text-gray-700 mb-2">
                      {{ trans('leakblock.settings.confirmPassword') }}
                    </label>
                    <input type="password" id="confirmPassword" v-model="confirmPassword" required
                           class="input-field">
                  </div>
                  <button type="submit" class="primary-button">
                    {{ trans('leakblock.settings.changePasswordButton') }}
                  </button>
                </form>
              </div>
            </div>
          </div>

          <!-- Billing Settings -->
          <div v-show="currentTab === 'billing'" class="space-y-6">
            <div class="bg-white rounded-lg shadow-sm p-6 intro-y">
              <h2 class="text-2xl font-semibold text-gray-700 mb-6">{{ trans('leakblock.settings.billingSettings') }}</h2>

              <!-- Invoice Address Form -->
              <form @submit.prevent="updateInvoiceAddress" class="max-w-2xl">
                <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
                  <div>
                    <label for="firstName" class="block text-sm font-medium text-gray-700 mb-2">
                      {{ trans('leakblock.settings.firstName') }}
                    </label>
                    <input type="text" id="firstName" v-model="invoiceAddress.firstName" required
                           class="input-field">
                  </div>
                  <div>
                    <label for="lastName" class="block text-sm font-medium text-gray-700 mb-2">
                      {{ trans('leakblock.settings.lastName') }}
                    </label>
                    <input type="text" id="lastName" v-model="invoiceAddress.lastName" required
                           class="input-field">
                  </div>
                  <div class="sm:col-span-2">
                    <label for="streetAndNumber" class="block text-sm font-medium text-gray-700 mb-2">
                      {{ trans('leakblock.settings.streetAndNumber') }}
                    </label>
                    <input type="text" id="streetAndNumber" v-model="invoiceAddress.streetAndNumber" required
                           class="input-field">
                  </div>
                  <div>
                    <label for="zipCode" class="block text-sm font-medium text-gray-700 mb-2">
                      {{ trans('leakblock.settings.zipCode') }}
                    </label>
                    <input type="text" id="zipCode" v-model="invoiceAddress.zipCode" required
                           class="input-field">
                  </div>
                  <div>
                    <label for="city" class="block text-sm font-medium text-gray-700 mb-2">
                      {{ trans('leakblock.settings.city') }}
                    </label>
                    <input type="text" id="city" v-model="invoiceAddress.city" required
                           class="input-field">
                  </div>
                  <div>
                    <label for="country" class="block text-sm font-medium text-gray-700 mb-2">
                      {{ trans('leakblock.settings.country') }}
                    </label>
                    <input type="text" id="country" v-model="invoiceAddress.country" required
                           class="input-field">
                  </div>
                  <div>
                    <label for="taxNumber" class="block text-sm font-medium text-gray-700 mb-2">
                      {{ trans('leakblock.settings.taxNumber') }}
                    </label>
                    <input type="text" id="taxNumber" v-model="invoiceAddress.taxNumber"
                           class="input-field">
                  </div>
                </div>
                <div class="mt-6">
                  <button type="submit" class="primary-button">
                    {{ trans('leakblock.settings.updateInvoiceAddressButton') }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Snackbar
        :message="snackbarMessage"
        :show="showSnackbar"
        :color="snackbarColor"
        @close="showSnackbar = false"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { trans } from "../../../js/utilities/leakblock-translations";
import Snackbar from "../Snackbar/Snackbar.vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';

const props = defineProps({
  user: Object,
});

const tabs = [
  { id: 'account', name: trans('leakblock.settings.tabs.account') },
  { id: 'security', name: trans('leakblock.settings.tabs.security') },
  { id: 'billing', name: trans('leakblock.settings.tabs.billing') }
];

const swiperModules = [FreeMode, Mousewheel];
const swiper = ref(null);
const currentTab = ref('account');
const currentEmail = ref('');
const newEmail = ref('');
const currentPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');
const communicationLanguage = ref('');
const invoiceAddress = ref({
  firstName: '',
  lastName: '',
  streetAndNumber: '',
  zipCode: '',
  city: '',
  country: '',
  taxNumber: ''
});

const snackbarMessage = ref('');
const showSnackbar = ref(false);
const snackbarColor = ref('red');

const setSwiper = (swiperInstance) => {
  swiper.value = swiperInstance;
};

const handlePrevClick = () => {
  swiper.value.slidePrev();
  const currentIndex = tabs.findIndex(tab => tab.id === currentTab.value);
  const prevIndex = Math.max(0, currentIndex - 1);
  currentTab.value = tabs[prevIndex].id;
};

const handleNextClick = () => {
  swiper.value.slideNext();
  const currentIndex = tabs.findIndex(tab => tab.id === currentTab.value);
  const nextIndex = Math.min(tabs.length - 1, currentIndex + 1);
  currentTab.value = tabs[nextIndex].id;
};

const showSnackbarMessage = (message, color = 'red') => {
  snackbarMessage.value = message;
  snackbarColor.value = color;
  showSnackbar.value = true;
};

onMounted(async () => {
  currentEmail.value = props.user.email;
  communicationLanguage.value = props.user.communicationLanguage || props.user.language || 'en';
  if (props.user.invoiceAddress) {
    invoiceAddress.value = props.user.invoiceAddress;
  }
});

const updateCommunicationLanguage = async () => {
  try {
    await axios.post('/api/user/communication-language', {
      language: communicationLanguage.value
    });
    showSnackbarMessage(trans('leakblock.settings.success.languageUpdated'), 'green');
  } catch (error) {
    console.error('Error updating language:', error);
    showSnackbarMessage(trans('leakblock.settings.errors.languageUpdate'));
  }
};

const changeEmail = async () => {
  try {
    await axios.post('/api/user/change-email', { newEmail: newEmail.value });
    showSnackbarMessage(trans('leakblock.settings.success.emailConfirmation'), 'green');
    newEmail.value = '';
  } catch (error) {
    console.error('Error changing email:', error);
    showSnackbarMessage(trans('leakblock.settings.errors.emailChange'));
  }
};

const changePassword = async () => {
  if (newPassword.value !== confirmPassword.value) {
    showSnackbarMessage(trans('leakblock.settings.errors.passwordsNotMatching'));
    return;
  }
  try {
    await axios.post('/api/user/change-password', {
      currentPassword: currentPassword.value,
      newPassword: newPassword.value
    });
    showSnackbarMessage(trans('leakblock.settings.success.passwordChanged'), 'green');
    currentPassword.value = '';
    newPassword.value = '';
    confirmPassword.value = '';
  } catch (error) {
    console.error('Error changing password:', error);
    showSnackbarMessage(trans('leakblock.settings.errors.passwordChange'));
  }
};

const updateInvoiceAddress = async () => {
  try {
    await axios.post('/api/user/invoice-address', invoiceAddress.value);
    showSnackbarMessage(trans('leakblock.settings.success.addressUpdated'), 'green');
  } catch (error) {
    console.error('Error updating invoice address:', error);
    showSnackbarMessage(trans('leakblock.settings.errors.addressUpdate'));
  }
};
</script>

<style scoped>
.input-field {
  @apply mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50;
}

.primary-button {
  @apply flex items-center text-white bg-primary-500 hover:bg-primary-400 px-4 py-2 transition rounded-md font-medium;
}

.navigation-button {
  @apply p-2 rounded-md bg-white text-gray-600 hover:bg-gray-50 lb-shadow;
}

.settings-swiper {
  overflow: visible !important;
}

.settings-slide {
  width: auto;
}

/* Hide scrollbars */
.settings-swiper,
.settings-swiper :deep(.swiper-wrapper) {
  overflow: visible !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.settings-swiper::-webkit-scrollbar,
.settings-swiper :deep(.swiper-wrapper)::-webkit-scrollbar,
.settings-swiper :deep(.swiper-scrollbar) {
  display: none !important;
}

.lb-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>