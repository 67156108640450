<template>
  <div class="rounded-md lb-shadow xl:col-span-1 md:overflow-hidden relative min-h-[460px]">
    <h3 class="flex items-center text-lg font-semibold relative bg-white px-6 py-4 rounded-t-md">
      {{ trans('leakblock.relatedAccountsOverview.title') }}
    </h3>
    <OverlayScrollbarsComponent :options="options" :events="events" ref="osComponent" class="md:h-full md:overflow-y-scroll">
      <div id="fullWidthTabContent" class="px-3 md:px-6 pt-3 pb-[60px] md:pb-[100px]" ref="osComponentMaxScrollHeight" data-overlayscrollbars-initialize>
        <div v-if="isLoading" class="flex justify-center items-center h-64">
          <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
        <div v-else>
          <div class="intro-y mb-6">
            <a :href="mainAccount.link" target="_blank" rel="noopener noreferrer" class="block bg-white p-4 rounded-md shadow-sm transition duration-300 hover:scale-[1.02]">
              <h4 class="font-semibold mb-2">{{ trans('leakblock.relatedAccountsOverview.mainAccount') }}</h4>
              <div class="flex items-center">
                <img :src="mainAccount.platform.svg_name" alt="Platform Icon" class="w-6 h-6 flex-shrink-0 mr-2">
                <span class="truncate text-primary-500" :title="mainAccount.link">
                  {{ mainAccount.username }}
                </span>
              </div>
            </a>
          </div>
          <div v-if="relatedAccounts.length > 0">
            <h4 class="font-semibold mb-2">{{ trans('leakblock.relatedAccountsOverview.linkedAccounts') }}</h4>
            <ul class="space-y-2">
              <li v-for="account in relatedAccounts" :key="account.id" class="intro-y">
                <a :href="account.link" target="_blank" rel="noopener noreferrer" class="block bg-white p-3 rounded-md shadow-sm transition duration-300 hover:scale-[1.02]">
                  <div class="flex items-center">
                    <img :src="account.platform.svg_name" alt="Platform Icon" class="w-6 h-6 flex-shrink-0 mr-2">
                    <span class="truncate text-primary-500" :title="account.link">
                      {{ account.username }}
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div v-else class="text-center text-gray-500 py-4">
            {{ trans('leakblock.relatedAccountsOverview.noLinkedAccounts') }}
          </div>
        </div>
      </div>
    </OverlayScrollbarsComponent>
    <div class="flex w-full bg-transparent absolute bottom-0 left-0 lb-transparent-background h-[60px] border-t border-gray-200 rounded-b-md px-6 items-center justify-end">
      <router-link
          :to="{ name: 'user-settings', params: { lang: $route.params.lang } }"
          class="text-gray-600 hover:text-primary-500 focus:outline-none mr-auto"
          :title="trans('leakblock.relatedAccountsOverview.tooltips.settings')"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      </router-link>
      <router-link
          :to="{ name: 'editSocialAccount', params: { lang: $route.params.lang, accountUrl: accountUrl } }"
          class="text-gray-600 hover:text-primary-500 focus:outline-none mr-4"
          :title="trans('leakblock.relatedAccountsOverview.tooltips.edit')"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
        </svg>
      </router-link>
      <button
          @click="openDeleteConfirmation"
          class="text-gray-600 hover:text-red-500 focus:outline-none"
          :title="trans('leakblock.relatedAccountsOverview.tooltips.delete')"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue";
import Swal from 'sweetalert2';
import { trans } from "../../../js/utilities/leakblock-translations";

const props = defineProps({
  accountUrl: {
    type: String,
    required: true
  }
});

const mainAccount = ref(null);
const relatedAccounts = ref([]);
const isLoading = ref(true);
const showScrollHintTop = ref(false);
const hideScrollHintBottom = ref(false);
const osComponent = ref(null);
const osComponentMaxScrollHeight = ref(null);

const options = {
  scrollbars: {
    autoHide: 'scroll'
  }
};

const events = {
  scroll: () => {
    const { viewport } = osComponent.value.osInstance().elements();
    const { scrollHeight, offsetHeight, scrollTop } = viewport;
    initializeScrollListener(scrollHeight, offsetHeight, scrollTop);
  }
};

const fetchAccountData = async () => {
  isLoading.value = true;
  try {
    const response = await axios.get(`/api/social-media-accounts/${props.accountUrl}`);
    mainAccount.value = {
      id: response.data.id,
      link: response.data.link,
      username: response.data.username,
      platform: response.data.platform
    };
    relatedAccounts.value = response.data.relatedAccounts;
  } catch (error) {
    console.error('Error fetching account data:', error);
  } finally {
    isLoading.value = false;
  }
};

const openDeleteConfirmation = () => {
  Swal.fire({
    title: trans('leakblock.relatedAccountsOverview.deleteModal.title'),
    text: trans('leakblock.relatedAccountsOverview.deleteModal.text'),
    icon: 'warning',
    iconColor: '#4B5563', // gray-600
    showCancelButton: true,
    confirmButtonColor: '#111827', // gray-900
    cancelButtonColor: '#F3F4F6', // gray-100
    confirmButtonText: trans('leakblock.relatedAccountsOverview.deleteModal.confirm'),
    cancelButtonText: trans('leakblock.relatedAccountsOverview.deleteModal.cancel'),
    reverseButtons: true,
    buttonsStyling: true,
    customClass: {
      popup: 'lb-modal-popup',
      title: 'lb-modal-title',
      htmlContainer: 'lb-modal-content',
      confirmButton: 'lb-modal-confirm',
      cancelButton: 'lb-modal-cancel',
      actions: 'lb-modal-actions'
    }
  }).then((result) => {
    if (result.isConfirmed) {
      deleteAccount();
    }
  });
};

const deleteAccount = async () => {
  try {
    await axios.delete(`/api/social-media-accounts/${props.accountUrl}`);
    await Swal.fire({
      title: trans('leakblock.relatedAccountsOverview.deleteModal.successTitle'),
      text: trans('leakblock.relatedAccountsOverview.deleteModal.successText'),
      icon: 'success',
      iconColor: '#111827', // gray-900
      confirmButtonColor: '#111827', // gray-900
      customClass: {
        popup: 'lb-modal-popup',
        title: 'lb-modal-title',
        htmlContainer: 'lb-modal-content',
        confirmButton: 'lb-modal-confirm'
      }
    });
    location.reload();
  } catch (error) {
    console.error('Error deleting account:', error);
    await Swal.fire({
      title: trans('leakblock.relatedAccountsOverview.deleteModal.errorTitle'),
      text: trans('leakblock.relatedAccountsOverview.deleteModal.errorText'),
      icon: 'error',
      iconColor: '#111827', // gray-900
      confirmButtonColor: '#111827', // gray-900
      customClass: {
        popup: 'lb-modal-popup',
        title: 'lb-modal-title',
        htmlContainer: 'lb-modal-content',
        confirmButton: 'lb-modal-confirm'
      }
    });
  }
};

const initializeScrollListener = (scrollHeight, offsetHeight, scrollTop) => {
  hideScrollHintBottom.value = scrollHeight - offsetHeight <= scrollTop;
  showScrollHintTop.value = scrollTop > 0;
};

onMounted(() => {
  fetchAccountData();
});

watch(() => props.accountUrl, fetchAccountData);
</script>