<template>
  <div :class="containerClasses">
    <div class="flex-none w-full h-full">
      <h2 class="text-2xl font-bold mb-4">{{ trans('leakblock.stepThree.title') }}</h2>
      <p class="text-gray-600">
        {{ trans('leakblock.stepThree.description') }}
      </p>
      <hr class="h-px mt-4 bg-gray-200 border-0">
      <div class="overflow-y-scroll w-full h-full pt-4 pb-[100px]">
        <h3 class="text-xl font-semibold mb-2">{{ trans('leakblock.stepThree.mainAccount') }}</h3>
        <div class="flex flex-col w-full list-item list-none mb-4">
          <div class="flex items-center w-full">
            <img :src="getPlatformIcon(props.formData.mainPlatformId)" alt="" class="w-12 h-12 mr-4">
            <div class="block md:flex flex-1 min-w-0">
              <div class="min-w-[9rem] max-w-[9rem] text-gray-700 truncate">{{ getPlatformName(props.formData.mainPlatformId, props.formData.customPlatformName) }}</div>
              <div class="grow text-gray-700 truncate">{{ props.formData.username }}</div>
            </div>
          </div>
        </div>
        <h3 class="text-xl font-semibold mb-2 mt-4">{{ trans('leakblock.stepThree.additionalAccounts') }}</h3>
        <transition-group name="list" tag="div">
          <div
              v-for="(account, index) in props.formData.linkedAccounts"
              :key="index"
              class="flex flex-col w-full list-item list-none mb-4"
              :class="{
                  'mb-4 md:mb-[100px]': index === props.formData.linkedAccounts.length - 1,
                  'mb-4': index !== props.formData.linkedAccounts.length - 1
                }"
          >
            <div class="flex items-center w-full">
              <img :src="getPlatformIcon(account.platformId)" alt="" class="w-12 h-12 mr-4">
              <div class="flex flex-1 min-w-0">
                <div v-if="account.platformId === 14" class="block md:flex items-center grow min-w-0">
                  <div class="min-w-[9rem] max-w-[9rem] text-gray-700 truncate">{{ account.platformName }}</div>
                  <div class="grow text-gray-700 truncate">{{ account.username }}</div>
                </div>
                <div v-else class="block md:flex items-center grow min-w-0">
                  <div class="min-w-[9rem] max-w-[9rem] text-gray-700 truncate">{{ getPlatformName(account.platformId, account.platformName) }}</div>
                  <div class="grow text-gray-700 truncate">{{ account.username }}</div>
                </div>
              </div>
            </div>
            <hr v-if="index !== props.formData.linkedAccounts.length - 1" class="h-px my-4 bg-gray-200 border-0">
          </div>
        </transition-group>
      </div>
    </div>

    <div class="flex w-full bg-transparent absolute bottom-0 left-0 lb-transparent-background h-[90px] border-t border-gray-200 rounded-br-md">
      <hr class="h-px my-8 bg-gray-200 border-0 mx-[-2.5rem]">
      <button @click="prevStep" class="flex items-center text-primary-500 bg-white border border-primary-500 hover:bg-primary-500 hover:text-white focus:ring-4 focus:ring-primary-200 font-medium text-sm focus:outline-none transition duration-300 px-4 py-2 rounded-md self-center mr-auto ml-[7.5rem]">
        <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path>
        </svg>
        {{ trans('leakblock.stepThree.back') }}
      </button>
      <button @click="confirmData" class="flex items-center text-white bg-primary-500 hover:bg-primary-400 px-4 py-2 transition rounded-md self-center ml-auto mr-10 font-medium">
        {{ trans('leakblock.stepThree.confirm') }}
        <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
        </svg>
      </button>
    </div>
    <Snackbar :message="snackbarMessage" :show="showSnackbar" @close="closeSnackbar"/>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import axios from 'axios';
  import Snackbar from "../../Snackbar/Snackbar.vue";
  import {useRouter} from "vue-router";
  import { trans } from "../../../../js/utilities/leakblock-translations";

  const props = defineProps({
    formData: Object,
    currentStep: Number,
    platforms: Array,
    isEditing: Boolean,
    accountUrl: String,
  });

  const emit = defineEmits(['next', 'prev']);

  const snackbarMessage = ref('');
  const showSnackbar = ref(false);

  const triggerSnackbar = (message) => {
    snackbarMessage.value = message;
    showSnackbar.value = true;
  };

  const closeSnackbar = () => {
    showSnackbar.value = false;
  };

  const prevStep = () => {
    emit('prev');
  };

  const router = useRouter()

  const generateSuccessPageRoute = (lang) => {
    return lang === 'de' ? '/de/social-media-account-hinzufuegen/erfolg' : '/en/add-social-account/success';
  };

  const goToSuccessPage = () => {
    router.push(generateSuccessPageRoute(trans()))
  };

  const confirmData = async () => {
    try {
      let response;
      if (props.isEditing) {
        response = await axios.put(`/api/social-media-accounts/${props.accountUrl}`, props.formData);
      } else {
        response = await axios.post('/api/social-media-accounts', props.formData);
      }

      if (response.status === 200 || response.status === 201) {
        goToSuccessPage();
      } else {
        triggerSnackbar(trans('leakblock.stepThree.error'));
      }
    } catch (error) {
      console.error('Error:', error);
      triggerSnackbar(trans('leakblock.stepThree.error'));
    }
  };

  const getPlatformIcon = (platformId) => {
    const platform = props.platforms.find(p => p.id === platformId);
    return platform ? platform.icon : '/build/images/icons/social-media/generic-social-media.svg';
  };

  const getPlatformName = (platformId, customPlatformName = '') => {
    if (platformId === 14) {
      return customPlatformName;
    }
    const platform = props.platforms.find(p => p.id === platformId);
    return platform ? platform.name : 14;
  };

  const containerClasses = computed(() => {
    return {
      'w-full md:w-2/3 flex flex-col bg-white py-4 px-10 md:p-10 md:pb-0 justify-between items-center overflow-hidden relative rounded-bl-md rounded-br-md md:rounded-tr-md md:rounded-br-mdmd:rounded-tl-none md:rounded-bl-none lb-shadow md:shadow-none': true,
      'pb-[120px]': props.formData.linkedAccounts.length === 0,
      'pb-4': props.formData.linkedAccounts.length > 0,
    };
  });
</script>

<style scoped>
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
