import axios from 'axios';
import { SessionChecker } from './session-checker';

axios.defaults.baseURL = '';
axios.defaults.headers.common['Content-Type'] = 'application/json';

let sessionChecker;

export function initializeSessionChecker(router) {
    sessionChecker = new SessionChecker(router);
    sessionChecker.setupRouterGuard();
    return sessionChecker;
}

export function getSessionChecker() {
    return sessionChecker;
}

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response) {
            if (error.response.status === 401) {
                const isLoginAttempt = error.config.url.includes('/login/auth');

                // Get the session checker instance
                const checker = getSessionChecker();

                // Check if we're on a public route
                if (checker && checker.isPublicRoute(window.location.pathname)) {
                    return Promise.reject(error);
                }

                if (isLoginAttempt) {
                    return Promise.reject(error);
                } else {
                    localStorage.removeItem('user');
                    const currentLang = localStorage.getItem('preferredLanguage') || 'en';

                    // Only redirect if we're on a protected route
                    if (checker && checker.isProtectedRoute(window.location.pathname)) {
                        window.location.href = `/${currentLang}/login?session_expired=true`;
                    }
                }
            }

            if (error.response.status === 403) {
                const currentLang = localStorage.getItem('preferredLanguage') || 'en';
                window.location.href = `/${currentLang}/error/forbidden`;
            }
        }

        return Promise.reject(error);
    }
);

export default axios;