<template>
  <header class="bg-white fixed top-0 w-full shadow-md z-50">
    <div class="mx-auto">
      <nav class="container flex items-center justify-between h-11 lg:h-14">
        <!-- Logo -->
        <div class="flex-shrink-0">
          <router-link :to="`/${currentLang}/dashboard`" class="flex items-center">
            <img src="/build/images/logo.svg" alt="Leakblock Logo" class="h-4 w-auto"/>
          </router-link>
        </div>

        <!-- Desktop Navigation -->
        <div class="hidden lg:flex items-center justify-center flex-1">
          <router-link :to="`/${currentLang}/dashboard`" class="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-100 transition duration-150 ease-in-out">
            {{ trans('leakblock.userHeader.dashboard') }}
          </router-link>
          <router-link :to="`/${currentLang}/messenger`" class="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-100 transition duration-150 ease-in-out">
            {{ trans('leakblock.userHeader.messenger') }}
          </router-link>
          <router-link :to="{ name: 'user-settings', params: { lang: currentLang } }" class="px-3 py-2 rounded-md text-sm font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-100 transition duration-150 ease-in-out">
            {{ trans('leakblock.userHeader.settings') }}
          </router-link>
        </div>

        <!-- Language switcher and Logout button -->
        <div class="flex items-center space-x-4">
          <!-- Language Switcher -->
          <div class="hidden lg:flex items-center border rounded-md">
            <button
                @click="switchLanguage('de')"
                :class="{'bg-primary-500 text-white': currentLang === 'de', 'text-gray-700': currentLang !== 'de'}"
                class="px-2 py-1 text-sm font-medium rounded-l-md hover:bg-primary-100 transition duration-150 ease-in-out"
            >
              DE
            </button>
            <button
                @click="switchLanguage('en')"
                :class="{'bg-primary-500 text-white': currentLang === 'en', 'text-gray-700': currentLang !== 'en'}"
                class="px-2 py-1 text-sm font-medium rounded-r-md hover:bg-primary-100 transition duration-150 ease-in-out"
            >
              EN
            </button>
          </div>

          <!-- Desktop Logout Button -->
          <div class="hidden lg:block">
            <a href="/logout"
               class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 hover:text-primary-600 hover:bg-gray-100 transition duration-150 ease-in-out">
              <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
              {{ trans('leakblock.userHeader.logout') }}
            </a>
          </div>
        </div>

        <!-- Mobile menu button -->
        <div class="flex items-center lg:hidden">
          <button
              @click="isMenuOpen = !isMenuOpen"
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
              aria-expanded="false"
          >
            <span class="sr-only">{{ trans('leakblock.userHeader.openMainMenu') }}</span>
            <div class="relative w-7 h-7">
              <!-- Three line icon -->
              <svg
                  class="absolute transition-all duration-300 ease-in-out text-primary-500"
                  :class="isMenuOpen ? 'opacity-0 rotate-180 scale-0' : 'opacity-100 rotate-0 scale-100'"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M3 6h18M3 12h18M3 18h18" />
              </svg>
              <!-- X icon -->
              <svg
                  class="absolute transition-all duration-300 ease-in-out text-primary-500"
                  :class="isMenuOpen ? 'opacity-100 rotate-0 scale-100' : 'opacity-0 -rotate-180 scale-0'"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="M5 19L19 5M5 5l14 14" />
              </svg>
            </div>
          </button>
        </div>
      </nav>
    </div>

    <!-- Mobile menu -->
    <div
        class="lg:hidden container transition-all duration-300 ease-in-out overflow-hidden"
        :class="isMenuOpen ? 'max-h-[400px] opacity-100' : 'max-h-0 opacity-0'"
    >
      <div class="pt-2 pb-3 space-y-1">
        <router-link
            @click="isMenuOpen = false"
            :to="`/${currentLang}/dashboard`"
            class="block py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-50"
        >
          {{ trans('leakblock.userHeader.dashboard') }}
        </router-link>
        <router-link
            @click="isMenuOpen = false"
            :to="`/${currentLang}/messenger`"
            class="block py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-50"
        >
          {{ trans('leakblock.userHeader.messenger') }}
        </router-link>
        <router-link
            @click="isMenuOpen = false"
            :to="{ name: 'user-settings', params: { lang: currentLang } }"
            class="block py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-50"
        >
          {{ trans('leakblock.userHeader.settings') }}
        </router-link>

        <!-- Mobile Logout Button -->
        <a
            @click="isMenuOpen = false"
            href="/logout"
            class="block py-2 rounded-md text-base font-medium text-gray-700 hover:text-primary-600 hover:bg-gray-50"
        >
          {{ trans('leakblock.userHeader.logout') }}
        </a>

        <!-- Language Switcher for Mobile -->
        <div class="flex items-center justify-center mt-4">
          <div class="inline-flex rounded-md shadow-sm" role="group">
            <button
                @click="switchLanguage('de')"
                :class="{'bg-primary-500 text-white': currentLang === 'de', 'bg-white text-gray-700': currentLang !== 'de'}"
                class="px-4 py-2 text-sm font-medium rounded-l-lg border border-gray-200 hover:bg-primary-100 focus:z-10 focus:ring-2 focus:ring-primary-500 transition duration-150 ease-in-out"
            >
              {{ trans('leakblock.userHeader.deutsch') }}
            </button>
            <button
                @click="switchLanguage('en')"
                :class="{'bg-primary-500 text-white': currentLang === 'en', 'bg-white text-gray-700': currentLang !== 'en'}"
                class="px-4 py-2 text-sm font-medium rounded-r-lg border border-gray-200 hover:bg-primary-100 focus:z-10 focus:ring-2 focus:ring-primary-500 transition duration-150 ease-in-out"
            >
              {{ trans('leakblock.userHeader.english') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { trans } from "../../js/utilities/leakblock-translations";

// Define props
const props = defineProps({
  user: Object,
  isLoading: {
    type: Boolean,
    default: false
  }
});

const isMenuOpen = ref(false);
const route = useRoute();
const router = useRouter();
const currentLang = ref(route.params.lang || 'en');

const switchLanguage = (lang) => {
  if (currentLang.value !== lang) {
    const newPath = route.fullPath.replace(`/${currentLang.value}/`, `/${lang}/`);
    router.push(newPath).then(() => {
      currentLang.value = lang;
      isMenuOpen.value = false;
      location.reload();
    });
  }
};

watch(() => route.params.lang, (newLang) => {
  if (newLang && (newLang === 'en' || newLang === 'de')) {
    currentLang.value = newLang;
  }
});

watch(route, () => {
  isMenuOpen.value = false;
}, { deep: true });
</script>