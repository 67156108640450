import ApexCharts from 'apexcharts';

const getTrafficChannelsChartOptions = () => {

    let trafficChannelsChartColors = {}

    if (document.documentElement.classList.contains('dark')) {
        trafficChannelsChartColors = {
            strokeColor: '#1f2937'
        };
    } else {
        trafficChannelsChartColors = {
            strokeColor: '#ffffff'
        }
    }

    return {
        series: [70, 20, 5, 5],
        labels: ['Abgeschlossen', 'In Bearbeitung', 'Rückfrage!', 'Gefunden'],
        colors: ['#84e1bc', '#faca15', '#771d1d', '#808080'],
        chart: {
            type: 'pie',
            height: 400,
            fontFamily: 'Inter, sans-serif',
            toolbar: {
                show: false
            },
        },
        responsive: [{
            breakpoint: 430,
            options: {
                chart: {
                    height: 300
                }
            }
        }],
        stroke: {
            colors: [trafficChannelsChartColors.strokeColor]
        },
        states: {
            hover: {
                filter: {
                    type: 'darken',
                    value: 0.9
                }
            }
        },
        tooltip: {
            shared: true,
            followCursor: false,
            fillSeriesColor: false,
            inverseOrder: true,
            style: {
                fontSize: '14px',
                fontFamily: 'Inter, sans-serif'
            },
            x: {
                show: true,
                formatter: function (_, { seriesIndex, w }) {
                    const label = w.config.labels[seriesIndex];
                    return label
                }
            },
            y: {
                formatter: function (value) {
                    return value + '%';
                }
            }
        },
        grid: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
    };
}

if (document.getElementById('total-statistics')) {
    const chart = new ApexCharts(document.getElementById('total-statistics'), getTrafficChannelsChartOptions());
    chart.render();

    // init again when toggling dark mode
    document.addEventListener('dark-mode', function () {
        chart.updateOptions(getTrafficChannelsChartOptions());
    });
}
