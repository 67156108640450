<template>
  <div class="w-full md:w-2/3 flex flex-col bg-white py-4 px-10 md:p-10 justify-between items-center overflow-hidden relative rounded-bl-md rounded-br-md md:rounded-tr-md md:rounded-br-md md:rounded-tl-none md:rounded-bl-none lb-shadow md:shadow-none">
    <!-- Main content with padding to prevent footer overlap on mobile -->
    <div class="flex-none w-full pb-[110px] md:pb-4"> <!-- Added padding bottom for mobile -->
      <h2 class="text-2xl font-bold mb-4">{{ trans('leakblock.editStepOne.title') }}</h2>
      <p class="text-gray-600 mb-4">
        {{ trans('leakblock.editStepOne.subtitle') }}
      </p>
      <div class="mb-4 p-4 bg-yellow-100 rounded-md">
        <p>{{ trans('leakblock.editStepOne.warningBox') }}</p>
      </div>
      <div class="flex flex-col sm:flex-row sm:items-center mb-4 gap-4">
        <img :src="props.mainAccount.platform.svg_name" alt="Platform Icon" class="w-12 h-12">
        <div class="flex-1 min-w-0">
          <p class="font-semibold">{{ props.mainAccount.platform.name }}</p>
          <p class="break-words">{{ props.mainAccount.link }}</p>
          <p v-if="props.mainAccount.username">{{ trans('leakblock.editStepOne.usernameLabel') }}: {{ props.mainAccount.username }}</p>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="flex w-full bg-transparent absolute bottom-0 left-0 lb-transparent-background h-[90px] border-t border-gray-200 rounded-br-md">
      <button @click="continueToNextStep"
              class="flex items-center text-white bg-primary-500 hover:bg-primary-400 px-4 py-2 transition rounded-md self-center ml-auto mr-10 font-medium">
        {{ trans('leakblock.editStepOne.nextButton') }}
        <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
             xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import {trans} from "../../../../js/utilities/leakblock-translations";

const props = defineProps({
  mainAccount: Object,
  formData: Object,
  currentStep: Number,
  platforms: Array,
});

const emit = defineEmits(['next', 'prev']);

const continueToNextStep = () => {
  // Ensure we're passing the existing main account data without modifications
  props.formData.mainPlatformId = props.mainAccount.platform.id;
  props.formData.mainPlattformProfileLink = props.mainAccount.link;

  // Check if username exists before setting it
  if (props.mainAccount.username) {
    props.formData.username = props.mainAccount.username;
  } else {
    console.warn('Username is missing from the main account');
    const usernameMatch = props.mainAccount.link.match(/\/([^\/]+)\/?$/);
    if (usernameMatch) {
      props.formData.username = usernameMatch[1];
    } else {
      console.error('Unable to extract username from link');
    }
  }

  // If it's PornHub (ID 8), set the account type
  if (props.mainAccount.platform.id === 8) {
    props.formData.phAccountType = props.mainAccount.link.includes('/pornstar/') ? 'pornstar' : 'model';
  }

  emit('next');
};
</script>