<!-- AccountSwitcher.vue -->
<template>
  <div v-if="accounts" class="accounts pt-6 mb-2 relative overflow-hidden flex items-start">
    <div class="swiper-container flex-grow">
      <Swiper
          :modules="swiperModules"
          :slides-per-view="'auto'"
          :space-between="8"
          :free-mode="true"
          :mousewheel="true"
          class="account-swiper m-0"
          @swiper="setSwiper"
      >
        <SwiperSlide
            v-for="(account, index) in accounts"
            :key="account.id"
            class="account-slide"
            :class="{
            'pre-selected': selectedAccount.account_url === account.account_url,
          }"
            :data-swiper-slide-index="index"
        >
          <div
              @click="selectAccount(account)"
              :class="{
              'bg-white border-b-4 border-primary-500': selectedAccount.account_url === account.account_url,
              'bg-white text-primary-500 hover:border-b-4 hover:border-primary-500': selectedAccount.account_url !== account.account_url
            }"
              class="py-2 px-10 rounded-md text-center flex items-center lb-shadow cursor-pointer h-full"
          >
            <div class="mr-2">
              <img v-if="account.platform.svg_name !== '/build/images/icons/social-media/generic-social-media.svg'" :src="account.platform.svg_name" alt="Account Icon" height="24" width="24">
              <img v-else src="/build/images/icons/social-media/generic-social-media.svg" alt="Account Icon" height="24" width="24">
            </div>
            {{ account.username }}
          </div>
        </SwiperSlide>
        <SwiperSlide class="account-slide hidden xl:block">
          <button @click="$emit('addNewAccount')" class="add-account-button">+</button>
        </SwiperSlide>
      </Swiper>
    </div>
    <div class="flex items-center ml-2">
      <button v-if="accounts.length > 1" @click="handlePrevClick" class="navigation-button mr-1">
        <span class="sr-only">Previous</span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
        </svg>
      </button>
      <button v-if="accounts.length > 1" @click="handleNextClick" class="navigation-button mr-1">
        <span class="sr-only">Next</span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </button>
      <button @click="$emit('addNewAccount')" class="add-account-button xl:hidden block">+</button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';

const props = defineProps({
  accounts: Array,
  selectedAccount: Object
});

const emit = defineEmits(['accountSelected', 'addNewAccount']);

const swiperModules = [FreeMode, Mousewheel];
const swiper = ref(null);

const currentIndex = computed(() => {
  return props.accounts.findIndex(account => account.account_url === props.selectedAccount.account_url);
});

const setSwiper = (swiperInstance) => {
  swiper.value = swiperInstance;
  swiper.value.slideTo(document.querySelector(`.pre-selected`).dataset.swiperSlideIndex, 0, false);
};

const selectAccount = (account) => {
  emit('accountSelected', account.account_url);
};

const handlePrevClick = () => {
  swiper.value.slidePrev();
  const prevIndex = Math.max(0, currentIndex.value - 1);
  const prevAccount = props.accounts[prevIndex];
  if (prevAccount) {
    selectAccount(prevAccount);
  }
};

const handleNextClick = () => {
  swiper.value.slideNext();
  const nextIndex = Math.min(props.accounts.length - 1, currentIndex.value + 1);
  const nextAccount = props.accounts[nextIndex];
  if (nextAccount) {
    selectAccount(nextAccount);
  }
};
</script>

<style scoped>
.swiper-container {
  overflow: hidden;
}

.account-swiper {
  overflow: visible !important;
}

.account-slide {
  width: auto;
}

/* Hide default scrollbar and Swiper scrollbar */
.account-swiper,
.account-swiper :deep(.swiper-wrapper) {
  overflow: visible !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.account-swiper::-webkit-scrollbar,
.account-swiper :deep(.swiper-wrapper)::-webkit-scrollbar,
.account-swiper :deep(.swiper-scrollbar) {
  display: none !important;
  width: 0 !important;
  height: 0 !important;
}
</style>