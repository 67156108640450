<template>
  <div class="pb-[100px] md:pb-0 w-full md:w-2/3 flex flex-col bg-white py-4 px-10 md:p-10 pb-4 md:pb-0 justify-between items-center overflow-hidden relative rounded-bl-md rounded-br-md md:rounded-tr-md md:rounded-br-md md:rounded-tl-none md:rounded-bl-none lb-shadow md:shadow-none">
    <div class="flex-none">
      <h2 class="text-2xl font-bold mb-4">{{ trans('leakblock.stepOne.title') }}</h2>
      <p class="text-gray-600">{{ trans('leakblock.stepOne.instructions') }}</p>
      <hr class="h-px mt-8 mb-4 bg-gray-200 border-0 mx-[-2.5rem]">
    </div>

    <!-- Warning Screen -->
    <div v-if="hasExistingAccounts && !warningConfirmed" class="flex flex-col flex-grow w-full">
      <div class="flex-grow">
        <div class="bg-red-100 rounded-md p-6">
          <h3 class="text-lg font-semibold text-red-800 mb-4">
            {{ trans('leakblock.stepOne.warningTitle') }}
          </h3>
          <p class="text-sm text-red-800 mb-4">
            {{ trans('leakblock.stepOne.existingAccountsWarning') }}
          </p>
          <p class="text-sm text-red-800 font-medium mb-4">
            {{ trans('leakblock.stepOne.samePersonWarning') }}
          </p>
          <p class="text-sm text-red-800 italic mb-4">
            {{ trans('leakblock.stepOne.currentAccountsInfo', { count: user.socialMediaAccounts?.length || 0 }) }}
          </p>
          <div class="mt-6">
            <label class="inline-flex items-center">
              <input
                  type="checkbox"
                  v-model="confirmedNewContent"
                  class="form-checkbox rounded border-gray-300 text-primary-500 focus:ring-primary-500"
              >
              <span class="ml-2 text-sm text-red-800">
                {{ trans('leakblock.stepOne.confirmNewContent') }}
              </span>
            </label>
          </div>
        </div>
      </div>

      <!-- Fixed bottom buttons for warning screen -->
      <div class="fixed bottom-0 left-0 right-0 md:relative bg-white border-t border-gray-200 p-4 flex gap-4">
        <button
            @click="handleCancel"
            class="flex-1 px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-md transition-colors duration-200"
        >
          {{ trans('leakblock.stepOne.cancel') }}
        </button>
        <button
            @click="confirmWarning"
            class="flex-1 px-4 py-2 bg-primary-500 hover:bg-primary-400 text-white rounded-md transition-colors duration-200"
            :disabled="!confirmedNewContent"
            :class="{ 'opacity-50 cursor-not-allowed': !confirmedNewContent }"
        >
          {{ trans('leakblock.stepOne.proceed') }}
        </button>
      </div>
    </div>

    <!-- Main Content -->
    <div v-else class="md:overflow-y-scroll w-full h-full no-scrollbar">
      <transition-group name="list" tag="div">
        <div
            v-for="(platform, index) in displayedPlatforms"
            :key="platform.id"
            class="flex flex-col py-1"
        >
          <div
              class="flex items-center mb-3"
              :class="{
              'md:pb-[100px]': !selectedPlatform && index === displayedPlatforms.length - 1,
              'mb-4': selectedPlatform || index !== displayedPlatforms.length - 1,
            }"
          >
            <div class="w-12 h-12 mr-7">
              <img :src="platform.icon" alt="">
            </div>
            <div class="text-primary-500 whitespace-nowrap text-left">
              {{ platform.name }}
            </div>
            <div class="whitespace-nowrap text-right grow mr-1">
              <a
                  href="#"
                  @click.prevent="togglePlatform(platform.id)"
                  :class="{
                     'text-primary-500 bg-white border border-primary-500 hover:bg-primary-500 hover:text-white': selectedPlatform !== platform.id,
                     'text-white bg-primary-500': selectedPlatform === platform.id
                   }"
                  class="font-medium rounded-md text-sm px-5 py-2.5 focus:outline-none transition duration-300"
              >
                {{ selectedPlatform === platform.id ? trans('leakblock.stepOne.deselect') : trans('leakblock.stepOne.select') }}
              </a>
            </div>
          </div>
          <hr v-if="index !== displayedPlatforms.length - 1" class="h-px my-4 bg-gray-200 border-0">
        </div>
      </transition-group>

      <div v-if="selectedPlatform" :class="{'mt-4 w-full': true, 'mb-[120px]': !isMd}">
        <!-- Rest of your existing platform-specific form fields -->
        <template v-if="selectedPlatform === 14">
          <label for="customPlatformName" class="block text-sm font-medium text-gray-700">{{ trans('leakblock.stepOne.customPlatformName') }}</label>
          <input
              type="text"
              id="customPlatformName"
              v-model="props.formData.customPlatformName"
              class="intro-x mt-4 mb-4 block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus:border-primary-500 focus:border-opacity-40"
              :placeholder="trans('leakblock.stepOne.customPlatformNamePlaceholder')"
          />
        </template>
        <template v-else-if="selectedPlatform === 8">
          <label for="phAccountType" class="block text-sm font-medium text-gray-700">{{ trans('leakblock.stepOne.accountType') }}</label>
          <select
              id="phAccountType"
              v-model="phAccountType"
              class="intro-x mt-4 mb-4 block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus:border-primary-500 focus:border-opacity-40"
              @change="updateProfileLink"
          >
            <option value="pornstar">{{ trans('leakblock.stepOne.pornstar') }}</option>
            <option value="cam">Camgirl</option>
          </select>
        </template>
        <label for="username" class="block text-sm font-medium text-gray-700">{{ trans('leakblock.stepOne.username') }}</label>
        <input
            type="text"
            id="username"
            v-model="username"
            class="intro-x mt-4 mb-4 block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus:border-primary-500 focus:border-opacity-40"
            :placeholder="trans('leakblock.stepOne.usernamePlaceholder')"
            @input="updateProfileLink"
        />
        <template v-if="selectedPlatform === 14">
          <label for="profileLink" class="block text-sm font-medium text-gray-700 mt-4">
            {{ trans('leakblock.stepOne.profileLinkLabel') }}
          </label>
          <input
              type="text"
              id="profileLink"
              v-model="props.formData.mainPlattformProfileLink"
              class="intro-x mt-4 block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus:border-primary-500 focus:border-opacity-40"
              :placeholder="trans('leakblock.stepOne.profileLinkPlaceholder')"
          />
        </template>
        <div v-else-if="profileLinkPreview" class="mt-2 text-sm text-gray-600">
          Link Preview:
          <a :href="profileLinkPreview" target="_blank" class="text-primary-500 hover:underline">
            {{ profileLinkPreview }}
          </a>
        </div>

        <div v-if="selectedPlatform && selectedPlatform !== 14" class="mt-4 p-4 bg-yellow-100 rounded-md">
          <p class="text-sm text-yellow-800">
            {{ trans('leakblock.stepOne.warning') }}
            <a :href="messengerLink" target="_blank" rel="noopener noreferrer" class="text-primary-500 underline">
              {{ trans('leakblock.stepOne.contactSupport') }}
            </a>.
          </p>
        </div>
      </div>
    </div>

    <!-- Main content bottom button -->
    <div v-if="!hasExistingAccounts || warningConfirmed"
         class="flex w-full bg-transparent absolute bottom-0 left-0 lb-transparent-background h-[90px] border-t border-gray-200 rounded-br-md">
      <hr class="h-px my-8 bg-gray-200 border-0 mx-[-2.5rem]">
      <button
          @click="validateNextStep"
          class="flex items-center text-white bg-primary-500 hover:bg-primary-400 px-4 py-2 transition rounded-md self-center ml-auto mr-10 font-medium">
        {{ trans('leakblock.stepOne.next') }}
        <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path>
        </svg>
      </button>
    </div>
    <Snackbar :message="snackbarMessage" :show="showSnackbar" @close="closeSnackbar"/>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
import Snackbar from "../../Snackbar/Snackbar.vue";
import { useRouter } from 'vue-router';
import { trans } from "../../../../js/utilities/leakblock-translations";
import axios from 'axios';

const props = defineProps({
  formData: Object,
  currentStep: Number,
  platforms: Array,
});

const emit = defineEmits(['next', 'prev']);
const router = useRouter();

const user = ref({});
const confirmedNewContent = ref(false);
const warningConfirmed = ref(false);
const selectedPlatform = ref(props.formData.mainPlatformId);
const username = ref('');
const profileLinkPreview = ref('');
const phAccountType = ref('pornstar');
const isMd = ref(false);
const displayedPlatforms = ref([...props.platforms]);
const snackbarMessage = ref('');
const showSnackbar = ref(false);

const hasExistingAccounts = computed(() => {
  return user.value?.socialMediaAccounts?.length > 0;
});

const messengerLink = computed(() => {
  return router.resolve({ name: 'messenger' }).href;
});

const fetchCurrentUser = async () => {
  try {
    const response = await axios.get('/api/current-user');
    user.value = response.data;
  } catch (error) {
    console.error('Error fetching current user:', error);
  }
};

const handleCancel = () => {
  router.back();
};

const confirmWarning = () => {
  if (confirmedNewContent.value) {
    warningConfirmed.value = true;
  }
};

const updateProfileLink = () => {
  if (selectedPlatform.value && selectedPlatform.value !== 14 && username.value) {
    const platform = props.platforms.find(p => p.id === selectedPlatform.value);
    if (platform && platform.urlTemplate) {
      let urlTemplate = platform.urlTemplate;
      if (selectedPlatform.value === 8) {
        urlTemplate = urlTemplate.replace('{accountType}', phAccountType.value);
      }
      profileLinkPreview.value = urlTemplate.replace('{username}', username.value);
      props.formData.mainPlattformProfileLink = profileLinkPreview.value;
    }
  }
  props.formData.username = username.value;
  if (selectedPlatform.value === 8) {
    props.formData.phAccountType = phAccountType.value;
  }
};

const triggerSnackbar = (message) => {
  snackbarMessage.value = message;
  showSnackbar.value = true;
};

const closeSnackbar = () => {
  showSnackbar.value = false;
};

const togglePlatform = (id) => {
  if (selectedPlatform.value === id) {
    deselectPlatform();
  } else {
    selectPlatform(id);
  }
};

const selectPlatform = (id) => {
  selectedPlatform.value = id;
  props.formData.mainPlatformId = id;
  displayedPlatforms.value = props.platforms.filter(platform => platform.id === id);
};

const deselectPlatform = () => {
  selectedPlatform.value = null;
  props.formData.mainPlatformId = '';
  props.formData.mainPlattformProfileLink = '';
  props.formData.customPlatformName = '';
  displayedPlatforms.value = [...props.platforms];
};

const validateNextStep = () => {
  if (hasExistingAccounts.value && !confirmedNewContent.value) {
    triggerSnackbar(trans('leakblock.stepOne.pleaseConfirmNewContent'));
    return;
  }

  if (!props.formData.mainPlatformId) {
    triggerSnackbar(trans('leakblock.stepOne.errors.selectPlatform'));
    return;
  }
  if (!username.value) {
    triggerSnackbar(trans('leakblock.stepOne.errors.enterUsername'));
    return;
  }
  if (props.formData.mainPlatformId === 14) {
    if (!props.formData.customPlatformName) {
      triggerSnackbar(trans('leakblock.stepOne.errors.enterPlatformName'));
      return;
    }
    if (!props.formData.mainPlattformProfileLink) {
      triggerSnackbar(trans('leakblock.stepOne.errors.enterProfileLink'));
      return;
    }
    if (!isValidUrl(props.formData.mainPlattformProfileLink)) {
      triggerSnackbar(trans('leakblock.stepOne.errors.enterValidProfileLink'));
      return;
    }
  }
  emit('next');
};

// ... (previous template and beginning of script remains the same until the isValidUrl regex)

const isValidUrl = (url) => {
  const regex = new RegExp(
      '^(https?:\\/\\/)?' +
      '((([a-zA-Z0-9$_.+!#&]+)(:[a-zA-Z0-9$_.+!#&]+)?@)?((([a-zA-Z0-9.-]+)\\.[a-zA-Z]{2,6})|(([0-9]{1,3}\\.){3}[0-9]{1,3}))|localhost)' +
      '(:[0-9]{1,5})?' +
      '(\\/[-a-zA-Z0-9%_.~+]*)*' +
      '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' +
      '(\\#[-a-zA-Z0-9_]*)?$',
      'i'
  );
  return regex.test(url);
};

watch([() => selectedPlatform.value, () => phAccountType.value], () => {
  updateProfileLink();
});

watch(() => selectedPlatform.value, () => {
  username.value = '';
  profileLinkPreview.value = '';
  props.formData.mainPlattformProfileLink = '';
  props.formData.username = '';
});

watch(() => props.formData.mainPlatformId, (newVal) => {
  selectedPlatform.value = newVal;
  if (newVal) {
    displayedPlatforms.value = props.platforms.filter(platform => platform.id === newVal);
  } else {
    displayedPlatforms.value = [...props.platforms];
  }
});

onMounted(async () => {
  await fetchCurrentUser();

  if (props.formData.mainPlatformId) {
    selectedPlatform.value = props.formData.mainPlatformId;
    displayedPlatforms.value = props.platforms.filter(platform => platform.id === props.formData.mainPlatformId);
  }

  const updateIsMd = () => {
    isMd.value = window.innerWidth >= 768;
  };
  updateIsMd();
  window.addEventListener('resize', updateIsMd);

  onUnmounted(() => {
    window.removeEventListener('resize', updateIsMd);
  });
});
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>