<!-- components/LeakblockFavicon.vue -->
<template>
  <img
      :src="faviconUrl"
      :alt="`${website} favicon`"
      class="w-4 h-4 mr-2 flex-shrink-0"
      @error="handleFaviconError"
  />
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    link: {
      type: String,
      required: true
    },
    website: {
      type: String,
      required: true
    }
  });

  const defaultFavicon = '/build/images/default-favicon.svg';

  const faviconUrl = computed(() => {
    return `https://www.google.com/s2/favicons?domain=${encodeURIComponent(props.link)}`;
  });

  const handleFaviconError = (event) => {
    event.target.src = defaultFavicon;
  };
</script>