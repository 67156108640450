<template>
  <div class="container bg-gray-100 pt-[30px] lg:pt-[45px] pb-[30px]">
    <template v-if="isLoading">
      <div class="flex justify-center items-center h-[300px]">
        <div class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    </template>
    <template v-else>
      <template v-if="accounts.length > 0">
        <AccountSwitcher
            :accounts="accounts"
            :selectedAccount="selectedAccount"
            @accountSelected="setSelectedAccount"
            @addNewAccount="addNewAccount"
            :user="user"
        />
        <router-view v-slot="{ Component }">
          <transition name="slide-fade" mode="out-in">
            <component :is="Component" :key="$route.fullPath" :selectedAccount="selectedAccount" :user="user" />
          </transition>
        </router-view>
      </template>
      <template v-else>
        <EmptyDashboard @addNewAccount="addNewAccount" :user="user" />
      </template>
    </template>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import { transLocale } from "../../js/utilities/leakblock-translations";
import EmptyDashboard from "../pages/Dashboard/EmptyDashboard.vue";
import AccountSwitcher from "../components/Account/AccountSwitcher.vue"

const props = defineProps({
  user: Object,
});

const accounts = ref([]);
const selectedAccount = ref(null);
const isLoading = ref(true);
const router = useRouter();
const route = useRoute();

const fetchAccounts = async () => {
  isLoading.value = true;
  try {
    const response = await axios.get('/api/social-media-accounts');
    accounts.value = response.data;
    await updateSelectedAccount();
  } catch (error) {
    console.error('Error fetching social media accounts:', error);
  } finally {
    isLoading.value = false;
  }
};

const updateSelectedAccount = async () => {
  if (accounts.value.length === 0) return;

  const accountUrl = route.params.accountUrl;
  if (accountUrl) {
    selectedAccount.value = accounts.value.find(acc => acc.account_url === accountUrl) || accounts.value[0];
  } else {
    selectedAccount.value = accounts.value[0];
    await router.replace({
      name: 'accountDetail',
      params: {...route.params, accountUrl: selectedAccount.value.account_url}
    });
  }
};

const generateAddSocialAccountRoute = (lang) => {
  return lang === 'de' ? '/de/social-media-account-hinzufuegen' : '/en/add-social-account';
};

const addNewAccount = () => {
  router.push(generateAddSocialAccountRoute(transLocale()));
};

const setSelectedAccount = async (accountUrl) => {
  const account = accounts.value.find(acc => acc.account_url === accountUrl) || accounts.value[0];
  selectedAccount.value = account;
  await router.push({name: 'accountDetail', params: {...route.params, accountUrl: account.account_url}});
};

// Watch for changes in the route
watch(
    () => route.name,
    async (newRouteName) => {
      if (newRouteName === 'dashboardRoot' || newRouteName === 'dashboard') {
        await updateSelectedAccount();
      }
    }
);

onMounted(async () => {
  await fetchAccounts();
  if (route.name === 'dashboardRoot' || route.name === 'dashboard') {
    await updateSelectedAccount();
  }
});
</script>